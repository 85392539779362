import * as types from "./types";

const initialState = {
    types: {},
    categories: {},
    imgFolder: '',
    galleryType: {},
    galleryImageFolder: {},
}

const typesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_TYPE_SUCCESS:
            return {
                ...state,
                types: action.payload?.data?.[0],
                imgFolder: action.payload?.imgFolder,
            }
        case types.FETCH__GALLERY_TYPE_SUCCESS:
            return {
                ...state,
                galleryType: action.payload?.data?.[0],
                galleryImageFolder: action.payload?.imgFolder,
            }
        default:
            return state;
    }
};

export default typesReducer;