import React from 'react';

function SvgGradient({id}) {

    return (
        <defs>
            <linearGradient id={id}>
                <stop offset='0%' stopColor='#7d5819'/>
                <stop offset='50%' stopColor='#c6943b'/>
                <stop offset='60%' stopColor='#daae51'/>
                <stop offset='100%' stopColor='#f1cc6c'/>
            </linearGradient>
        </defs>

    );
}

export default SvgGradient;
