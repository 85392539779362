import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";

const TopContent = ({image, title, text, redirectRoute, contentClassName = '', backgroundClassName = '', buttonText}) => (
    <div style={{backgroundImage: `url(${image})`}} className={`top-content ${contentClassName}`}>
        <div className={`background-color ${backgroundClassName}`}>
            <div className='text-content'>
                {title && <h1 className='title'>{title}</h1>}
                {text && <p className='text'>{text}</p>}
                {redirectRoute &&
                    <Link to={redirectRoute}>
                        <button className='redirect-button'>{buttonText}</button>
                    </Link>
                }
            </div>
        </div>
    </div>
);

export default TopContent;