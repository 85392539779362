import * as React from "react"
import SvgGradient from "../../components/svgGradient";
import { v4 as uniqueId } from 'uuid';

function Email(props) {
const id = uniqueId()
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 168.09 105.66"
            width={16}
            height={16}
            {...props}
        >
            <SvgGradient id={id}/>
            <g data-name="\u0421\u043B\u043E\u0439 2" fill={props.fill? props.fill :`url(#${id})`}>
                <path
                    d="M83.94 55.84L11.3 0h145.46zM0 6v93.08l58.79-48zm10.68 99.67h147.06l-57.29-47.74-16.51 12.74-15.6-12.27zM168.09 99V6l-58.2 44.6z"
                    data-name="\u0421\u043B\u043E\u0439 1"
                />
            </g>
        </svg>
    )
}

export default Email
