import sendMessage from "./sendMessage";
import reviewsList from "./reviewsList";
import fetchDefaultData from "./fetchDefaultData";

const generalOperations = [
    sendMessage,
    reviewsList,
    fetchDefaultData,
];

export default generalOperations;