import React from 'react';
import {Link} from "react-router-dom";
import Roofing from "../../assets/svges/roofing";
import Plumbing from "../../assets/svges/plumbing";
import Bathroom from "../../assets/svges/bathroom";
import Kitchen from "../../assets/svges/kitchen";
import createImageUrl from "../../utils/createImageUrl";
import './style.scss';

const logos = {
    Roofing: <Roofing fill='#DBB257' width={51} height={47}/>,
    Plumbing: <Plumbing fill='#DBB257' width={51} height={47}/>,
    Bathroom: <Bathroom fill='#DBB257' width={51} height={47}/>,
    Kitchen: <Kitchen fill='#DBB257' width={51} height={47}/>,
}

const ServiceCard = ({intro_image, description, id, imageHeight, title, width, logo, folder, fileName}) => (
    <div
        className={description ? 'service-card-container service-card-container-height ' : 'service-card-container'}
        style={{width}}>
        <div className='imageContainer'>
            <div>
                <img src={createImageUrl(fileName || 'intro_image', folder, intro_image)} alt="#"
                     style={{height: imageHeight}}/>
                {logo ? <div className='bigCircle'>
                       <div className='circle'>{logos[title]}<span>{title}</span></div></div> :
                       <div className='buttonBorderCircle'><span>{title}</span></div>
                }
            </div>
        </div>
        {description && <p>{description}</p>}
        <Link to={`${id}`}>
            <div className='button-container'>
                <span>View more</span>
            </div>
        </Link>
    </div>
)

export default ServiceCard;
