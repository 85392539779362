import {combineReducers} from "redux";
import service from "../services/reducer";
import general from "../general/reducer";
import types from "../projectTypes/reducer";
import gallery from "../gallery/reducer";

const rootReducer = combineReducers({
    service,
    general,
    types,
    gallery
});

export default rootReducer;