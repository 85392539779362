import * as React from "react"
import SvgGradient from "../../components/svgGradient";
import {v4 as uniqueId} from 'uuid';

function LicensedAndBonded(props) {
    const id = uniqueId()
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 213.24 214.81"
            {...props}
            width={90}
            height={80}
            fill={'#AF7822'}
        >
            <SvgGradient id={id}/>
            <g data-name="\u0421\u043B\u043E\u0439 2" fill={props.fill ? props.fill : `url(#${id})`}>
                <g data-name="\u0421\u043B\u043E\u0439 1">
                    <path
                        d="M125 101.27c-3.43 0-6.7.09-10 0-3.74-.14-5.47-3-5.1-6.27.36-3.13 3.12-4.71 5.91-4.69h14.33a5.58 5.58 0 015.86 5.37q.06 46.76 0 93.51v1.45h48.09v-1.51V75.26c0-.86.12-1.73.17-2.6a.58.58 0 00-.19-.44c-2-1.52-4-3-5.91-4.53-2.47-1.94-4.91-3.9-7.36-5.86s-5.12-4.11-7.69-6.15c-2.89-2.29-5.8-4.55-8.7-6.83s-5.67-4.47-8.49-6.73c-3.47-2.79-6.9-5.62-10.37-8.4-2.13-1.71-4.32-3.37-6.46-5.07-2.72-2.16-5.42-4.36-8.14-6.52s-5.57-4.4-8.36-6.59c-1-.82-2-1.68-3.14-2.4-1.91-1.23-2.92-1.17-4.69 0-2.39 1.66-4.58 3.6-6.87 5.4-2.88 2.27-5.79 4.5-8.67 6.76-2 1.6-4 3.22-6.09 4.83-2.56 2-5.13 4-7.7 6-3.27 2.58-6.53 5.17-9.81 7.75C63 46 60.41 48 57.8 50.08l-9.21 7.21-9.1 7.14Q33.22 69.34 27 74.24c-2.25 1.77-4.44 3.6-6.68 5.36-3.18 2.5-6.39 5-9.6 7.44a12.61 12.61 0 01-3.19 2.25C4 90.5.2 89.12 0 84.53c-.12-2.62 1.4-4 3.21-5.44 2.31-1.78 4.59-3.62 6.88-5.42l7.33-5.77 15-11.85C37 52.5 41.46 48.93 46 45.39c2.75-2.15 5.55-4.24 8.31-6.38q6.3-4.9 12.57-9.84c3.74-3 7.44-5.94 11.19-8.88 3.29-2.59 6.63-5.12 9.92-7.71s6.48-5.16 9.71-7.75c1.15-.94 2.3-1.91 3.45-2.83a9.9 9.9 0 0112.95 1 9.13 9.13 0 001.2 1c2.68 2.05 5.38 4.06 8 6.13s5.41 4.34 8.12 6.5q7 5.61 14 11.21T159.58 39c3.91 3.08 7.85 6.13 11.75 9.23 4.1 3.25 8.15 6.55 12.23 9.82q5.83 4.68 11.7 9.33l10.91 8.75c1.68 1.34 3.39 2.67 5.08 4a5.2 5.2 0 011.94 5.07 4.69 4.69 0 01-3.31 4.28A5.3 5.3 0 01205 89c-2.76-2-5.39-4.26-8.08-6.4a4.68 4.68 0 00-.77-.55c-.6-.31-1-.24-1.1.55v113.78a5.39 5.39 0 01-3.86 5.3 2.46 2.46 0 01-.86 0h-60.44c-1.67 0-4.28-2-4.43-3.77s-.41-3.45-.42-5.17V103z"/>
                    <path
                        d="M102.6 132.85c0 6.71.17 13.43 0 20.14-.29 8.82-1.56 17.51-4.94 25.75-3.23 7.89-8.42 14.37-14.77 20a83.83 83.83 0 01-15.94 10.92c-2.81 1.51-5.64 3-8.46 4.47a5.18 5.18 0 01-5 .13 112.62 112.62 0 01-23.7-14.71c-10.38-8.7-16.92-19.57-19.23-33C9 157.35 8.86 148.1 9 138.84s.44-18.24.69-27.35c.08-3 1.66-4.81 4.74-5.07a79.38 79.38 0 0019.65-4A100.29 100.29 0 0051.74 94c.5-.29 1-.59 1.5-.84a5.2 5.2 0 015.33.09 98.09 98.09 0 0018.74 9 72.68 72.68 0 0018.67 4 14.76 14.76 0 011.49.17q4.4.63 4.5 5.09l.5 21.32zm-83.33-17.08c-.17 7.1-.39 14.2-.51 21.31-.15 8.83-.23 17.65 1.07 26.43a43.49 43.49 0 0012.27 24.92c6.73 6.8 14.75 11.69 23.26 15.87a1.45 1.45 0 001.17-.05c7.23-3.64 14.18-7.7 20.25-13.13a45 45 0 0012.14-16.61 59.17 59.17 0 003.92-22c.1-11.73-.24-23.47-.39-35.21 0-1.59 0-1.6-1.66-1.84a94.56 94.56 0 01-34-12 1.59 1.59 0 00-1.85 0A92.83 92.83 0 0128.37 114c-2.97.65-5.97 1.16-9.1 1.77z"/>
                    <path
                        d="M51.77 160.62l6.67-8.94 11.91-16a5.23 5.23 0 015.45-2.24 5.36 5.36 0 013.2 8.68c-2.78 3.79-5.62 7.54-8.43 11.31q-6.75 9.06-13.49 18.14a5.41 5.41 0 01-8.27.81Q43.54 167.24 38.3 162a5.4 5.4 0 012.7-9.11 5.09 5.09 0 014.92 1.52c1.94 1.98 3.8 4.02 5.85 6.21z"/>
                </g>
            </g>
        </svg>
    )
}

export default LicensedAndBonded;
