import React from 'react';
import './style.scss'
import avatar from '../../../assets/images/avatar.png'

const TeamCard = ({name, profession, description}) => {

    return (
        <div className='teamCardContainer'>
            <div className='teamLineCard'></div>
            <div className='avatar'>
                <img src={avatar} alt="#"/>
            </div>
            <div className='teamText'>
                <h2>{name}</h2>
                <div className='profession'><p>{profession}</p></div>
                <p>{description}</p>
            </div>
        </div>
    );
};

export default TeamCard;