import React, {Suspense, useEffect} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Team from "./pages/Team";
import Service from "./pages/Service";
import NotFound from './pages/NotFound'
import Contact from "./pages/Contact";
import AboutUs from "./pages/AboutUs";
import Gallery from "./pages/Gallery";
import Category from "./pages/Categories";
import {useDispatch} from "react-redux";
import {makeAction} from "./state/makeAction";
import {FETCH_DEFAULT_REQUEST} from "./state/general/types";
import GalleryCategories from "./pages/Gallery/GalleryCategories";
import GalleryTypes from "./pages/GalleryTypes";
import ServiceTypes from "./pages/ServiceTypes";

const App = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(makeAction(FETCH_DEFAULT_REQUEST))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Suspense fallback={<>...Loading</>}>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Navigate to='/home'/>}/>
                    <Route path='/*' element={<Navigate to='404'/>}/>
                    <Route path="/404" element={<NotFound/>}/>
                    <Route path="/home" element={<Home/>}/>

                    <Route path="/services" element={<Service/>}/>
                    <Route path="/services/:id" element={<ServiceTypes/>}/>
                    <Route path="/services/:id/category" element={<Category/>}/>

                    <Route path="/gallery" element={<Gallery/>}/>
                    <Route path="/gallery/:id" element={<GalleryTypes/>}/>
                    <Route path="/gallery/:id/category" element={<GalleryCategories/>}/>

                    <Route path="/aboutUs" element={<AboutUs/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/team" element={<Team/>}/>
                </Routes>
            </BrowserRouter>
        </Suspense>
    )
}

export default App;
