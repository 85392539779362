import React from 'react';
import ServiceChild from "../../../components/serviceChild";
import Roofing from "../../../assets/svges/roofing";
import Layout from "../../../components/layout";
import TopContent from "../../../components/topContent";
import roofingImage from "../../../assets/images/roofing.jpg";
import foamImage from "../../../assets/images/foam2.png";
import PageTitle from "../../../components/helmet";

const FoamRoof = () => {
    return (
        <Layout>
            <PageTitle title='Foam Roof – Timeless'/>
            <TopContent
                image={roofingImage}
                title='ROOFING'
                text='We are ready to help you with your roof maintenance, repair and full replacement.
                    We guarantee free inspection and provide with exceptional results. '
            />
            <ServiceChild>
                <div className='logoContainer'>
                    <div className='logoMargin'>
                        <Roofing fill='#B6812A' width={97} height={91}/>
                    </div>
                </div>
                <h5>Foam Roof</h5>
                <p>
                    Many homes and businesses here in California and elsewhere in the United States feature foam roofs. Foam roofs are incredibly durable, dependable, and relatively affordable to install, maintain, and repair. Foam roofs compare positively to other residential and commercial roofing choices, and it’s the superior flat roofing option. Finally, with rising energy costs and an increased emphasis on energy-efficient building design, foam roofs are becoming more popular because they’re incredibly efficient.
                    Foam roofing consists of polyurethane foam and elastomeric coating. Polyurethane foam is a material with many uses. It’s used in foam roofs as a high-density insulation material.
                    The elastomeric foam roof coating is a water-resistant membrane that is designed to effectively protect the roof against rain, water, and sun. A seamless layer of foam is applied to the roof surface. This foam offers superior protection against moisture.
                    There are several benefits of foam roofing for commercial and residential buildings:
                </p>
                <ol>
                    <li>Lower Energy Bills</li>
                    <li>Waterproofing</li>
                    <li>Maintain Structural Integrity</li>
                    <li>Proper Drainage</li>
                </ol>
                <div className='kitchenBottomContainer'>
                    <img src={foamImage} alt="#" className='kitchenBottom'/>
                </div>
                <p>
                    Foam can be applied on both existing and new roofing projects. Moreover, it can be applied on different types of roofs, including domed, pitched, flat, wood, metal, and concrete roofing.
                    Foam can also be applied on irregular-shaped roofs. It eliminates the need of costly tear-off in case of roof replacement.
                    Contact us at Timeless Construction for a FREE roof inspection. We’d love to talk to you more about foam roofs and your options for roof replacement.
                </p>
                <div className='kitchenBottomContainer'>
                    <img src={foamImage} alt="#" className='kitchenBottom'/>
                </div>
            </ServiceChild>
        </Layout>
    );
};

export default FoamRoof;