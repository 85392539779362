import React from 'react';
import shingle1 from "../../../assets/images/shingle1.png";
import shingle2 from "../../../assets/images/shingle2.png";
import shingle3 from "../../../assets/images/shingle3.png";
import shingle4 from "../../../assets/images/shingle4.png";
import shingle5 from "../../../assets/images/shingle5.png";
import shingle6 from "../../../assets/images/shingle6.png";
import shingle7 from "../../../assets/images/shingle7.png";
import Layout from "../../../components/layout";
import ServiceChild from "../../../components/serviceChild";
import Roofing from "../../../assets/svges/roofing";
import TopContent from "../../../components/topContent";
import roofingImage from "../../../assets/images/roofing.jpg";
import PageTitle from "../../../components/helmet";

const SingleRoof = () => {
    return (
        <Layout>
            <PageTitle title='Single Roof – Timeless'/>
            <TopContent
                image={roofingImage}
                title='ROOFING'
                text='We are ready to help you with your roof maintenance, repair and full replacement.
                    We guarantee free inspection and provide with exceptional results. '
            />
            <ServiceChild>
                <div className='logoContainer'>
                    <div className='logoMargin'>
                        <Roofing fill='#B6812A' width={97} height={91}/>
                    </div>
                </div>
                <h5>Shingle roof</h5>
                <p>
                    Asphalt shingles are also known as and composition shingles (or composite shingles). These are
                    general
                    terms for the same thing. The term “composition” is used because the shingles are a composite
                    product
                    made from either a fiberglass or cellulose mat, asphalt, and mineral granules. These components are
                    glued in layers, compressed, and blended, which makes the shingles waterproof, durable and
                    affordable
                    option for you.
                    Shingle fight algae growth, offer enhanced resistance to damaging hail, and offer cool roof color
                    technologies that reflect the heat of the sun to keep homes cooler and can reduce the expenses of
                    air
                    conditioning. Depending on your location, choosing a specialty shingle can even reduce your
                    insurance
                    cost. In addition, asphalt shingles are naturally fire resistant, making them an ideal choice for
                    areas
                    where wildfires are prominent.
                </p>
                <div className='kitchenBottomContainer'>
                    <img src={shingle1} alt="#" className='kitchenBottom'/>
                </div>
                <p>
                    There are three major categories of asphalt shingle roofing products available today - strip
                    shingles,
                    dimensional shingles, and luxury shingles. It is important to understand the differences between
                    each
                    type of roofing shingle in order to pick the right product for your home.
                </p>
                <p>
                    Strip shingles is the original and most basic type of shingle roof that is made of a single layer
                    asphalt shingle, it generally weighs and costs less than other asphalt shingles.
                </p>
                <p>
                    Dimensional shingles, also known as architectural or laminate shingles, is the most popular type of
                    asphalt shingle roof. It is manufactured with two or more layers of asphalt that are fused together
                    for
                    a thicker and richer multi-dimensional appearance and are engineered to replicate the wood shake and
                    natural slate roofing aesthetics. Dimensional shingles are typically heavier and have improved
                    warranty
                    protection.
                </p>
                <div className='twoImages'>
                    <img src={shingle2} alt="#"/>
                    <img src={shingle3} alt="#"/>
                </div>
                <p>
                    Luxury shingles is highest-quality laminated shingles available. Not only luxury shingles is
                    ‘heavyweights’ that offer premium protection for weathering, but it is also stunning to look upon,
                    presenting the highest quality. These products offer the most realistic representation of the
                    old-world
                    wood shake and quarried slate roofing.
                </p>
                <div className='twoImages'>
                    <img src={shingle4} alt="#"/>
                    <img src={shingle5} alt="#"/>
                </div>
                <p>
                    Benefits of getting shingle roof.
                </p>
                <ul>
                    <li>Durability</li>
                    <li>Affordability</li>
                    <li>Fire resistance</li>
                    <li>UV protection</li>
                    <li>Cool roof color technologies</li>
                    <li>Many colors available to match your roof design</li>
                </ul>
                <p>
                    Timeless Construction is specialized in shingle roofing and has been serving the greater Los Angeles
                    area since 1998! Our company is fully licensed, bonded and insured. Also, we are a Certified Select
                    Shingle Master Installer & Owens Corning Preferred Installer company.
                </p>
                <p>
                    Getting your roof done by our professional team will give you long-lasting results and peace of
                    mind.
                </p>
                <div className='twoImages'>
                    <img src={shingle6} alt="#"/>
                    <img src={shingle7} alt="#"/>
                </div>
            </ServiceChild>
        </Layout>
    );
};

export default SingleRoof;