import React from 'react';
import kitchen1 from '../../../assets/images/kitchen1.png'
import kitchen2 from '../../../assets/images/kitchen2.png'
import kitchen3 from '../../../assets/images/kitchen3.png'
import kitchen4 from '../../../assets/images/kitchen4.png'
import ServiceChild from "../../../components/serviceChild";
import Kitchen from "../../../assets/svges/kitchen";
import TopContent from "../../../components/topContent";
import PageTitle from "../../../components/helmet";
import createImageUrl from "../../../utils/createImageUrl";

function TypeWithOutChildren({types, imgFolder}) {
    return (
        <>
            <PageTitle title='Kitchen Remodeling – Timeless'/>
            <TopContent
                image={createImageUrl('cover_image', imgFolder, types?.cover_image)}
                title={types?.title}
                text={types?.description}
            />
            <ServiceChild>
                <div className='logoContainer'>
                    <div className='logoMargin'>
                        <Kitchen fill='#B6812A' width={97} height={91}/>
                    </div>
                </div>
                <h5>Kitchen Remodeling</h5>
                <p>The kitchen is considered to be the heart of the house to many homeowners in Los Angeles. It is
                    not just a place to prepare tasty food and consume it, but also to spend quality time with
                    family and friends, have conversations and nourish each other. Therefore, there are many
                    important decisions to make and steps to take for renovating the kitchen. That’s when Timeless
                    Construction comes to help. We will ensure the whole process is positive and guarantee that your
                    dream kitchen will be fully realized. We also think that kitchen renovation is good investment
                    that can also increase the value of your home.
                </p>


                <div className='kitchenBottomContainer'>
                    <img src={kitchen1} alt="#" className='kitchenBottom'/>
                </div>
                <p>
                    A kitchen remodel can range from something very minor to a very complex project. We do it all.
                    From installing high quality and energy efficient equipment, appliances, cabinets, countertops
                    to laying new electrical and plumbing systems. From gutting the space, moving walls to an entire
                    redesign.
                </p>
                <p>
                    Your budget will be the leading consideration in the decision-making process. And for that
                    reason, our team will have open and professional communication throughout the process. They will
                    suggest creative ideas and innovative design strategies to help you visualize your dream kitchen
                </p>
                <div className='twoImages'>
                    <img src={kitchen2} alt="#"/>
                    <img src={kitchen3} alt="#"/>
                </div>

                <p>
                    The kitchen must be designed in appropriate and suitable manner. We think that the room should
                    be
                    not only functional but also cozy and stylish. Therefore, the project manager has to consider
                    all details, for instance if you are a big household hosting parties you might need a functional
                    dishwasher or a spacious fridge. Or if you want to save on cabinets, you should invest in
                    quality countertops that will not only look appealing, but will outlive the effect of time. He
                    will also help you choose beautiful, functional and soft close cabinet that are not necessarily
                    matter of luxury any longer.
                </p>

                <div className='kitchenBottomContainer'>
                    <img src={kitchen4} alt="#" className='kitchenBottom'/>
                </div>
                <p>
                    After these discussions we will give you a free and itemized estimate. The project manager will
                    be involved in every step of the construction, to make sure you have positive and stress-free
                    experience.
                </p>
            </ServiceChild>
        </>
    );
}

export default TypeWithOutChildren;
