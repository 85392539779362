import {FETCH_DEFAULT_SUCCESS, FETCH_REVIEWS_REQUEST, FETCH_REVIEWS_SUCCESS, CLEAR_REVIEWS} from "./types";

const initialState = {
    defaultData: {},
    reviews: [],
    reviewsPageCount: 0,
    reviewsLoader: false,
}

const generalReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DEFAULT_SUCCESS:
            return {...state, defaultData: action.payload}
        case FETCH_REVIEWS_REQUEST:
            return {...state, reviewsLoader: true}
        case CLEAR_REVIEWS: {
            return {
                ...state, reviews: []
            }
        }
        case FETCH_REVIEWS_SUCCESS:
            return {
                ...state,
                reviewsPageCount: action.payload?.pageCount || 0,
                reviews: [...state.reviews, ...action.payload.data],
                reviewsLoader: false,
            }
        default:
            return {...state};
    }
};

export default generalReducer;