import * as React from "react"
function LeftNav(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={21.909}
            height={45.383}
            viewBox="0 0 21.909 45.383"
            {...props}
        >
            <defs>
                <linearGradient
                    id="a"
                    x1={-0.11}
                    y1={0.5}
                    x2={1.11}
                    y2={0.5}
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset={0} stopColor="#af7822" />
                    <stop offset={1} stopColor="#f1cc6c" />
                </linearGradient>
            </defs>
            <path
                data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 6 \u043A\u043E\u043F\u0438\u044F 4"
                d="M509.7 991.839l-18.363-21.044a3.86 3.86 0 010-2.6l18.363-21.042a.948.948 0 011.469 0l1.469 1.684a1.315 1.315 0 010 1.683L496.08 969.5l16.558 18.976a1.315 1.315 0 010 1.683l-1.469 1.684a.947.947 0 01-1.469 0z"
                transform="translate(-491.033 -946.805)"
                fill="url(#a)"
            />
        </svg>
    )
}
export default LeftNav
