import React from 'react';
import ExperiencedTeam from "../../assets/svges/experiencedTeam";
import FinancialOption from "../../assets/svges/financialOption";
import LicensedAndBonded from "../../assets/svges/licensedAndBonde";
import './style.scss'
import About from "./about";

const AboutDes = ({styles, aboutTopBorder}) => {
    const aboutData = [
        {
            icon: <ExperiencedTeam/>,
            title: 'Experienced Team',
            text: 'Experienced and dedicated professionals available to assist you at any moment'
        },
        {
            icon: <FinancialOption/>,
            title: 'Financial Option',
            text: 'A variety of financing options to meet your budget capacity'
        },
        {
            icon: <LicensedAndBonded/>,
            title: 'Licensed And Bonded',
            text: 'Licensed to perform our operations and bonded for professional protection'
        },
    ]
    return (
        <div className='aboutDesContent' style={styles}>
            <div className='about' style={{borderTop: aboutTopBorder ? '1px solid #cdc7c7' : 'none'}}>
                {
                    aboutData.map((el, index) => <About key={index} {...el}/>)
                }
            </div>
        </div>
    )
}

export default AboutDes;