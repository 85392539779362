import {useCallback, useState} from "react";
import {Link} from "react-router-dom";
import {isEmpty} from "lodash";

const getItem = (label, key, children) => ({label, key, children});

function useContainer({menu}) {
    const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
    const [openItems, setOpenItems] = useState([]);

    const items = useCallback((values) => values.map(item => {
        item = getItem(
            <Link className='item-link' to={{
                pathname: item.path,
                search: `${item.type ? `?type=${item.type}` : ''}`
            }}>
                {item?.name[0]?.toUpperCase()}{item?.name?.substring(1)?.toLocaleLowerCase()}
            </Link>,
            item.path + item.name,
            !isEmpty(item.children) ? items(item.children) : null);
        return item;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [menu]);

    const onMenuOpenChange = useCallback(() => {
        setMobileMenuVisible(prev => !prev)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mobileMenuVisible]);

    return {
        mobileMenuVisible,
        onMenuOpenChange,
        items,
        openItems,
        setOpenItems,
    }
}

export default useContainer;
