import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {makeAction} from "../../state/makeAction";
import {FETCH_SERVICES_REQUEST} from "../../state/services/types";

function useContainer() {
    const dispatch = useDispatch();
    const { services, imgFolder } = useSelector(({service}) => service)
    const onMountHandler = () => {
        dispatch(makeAction(FETCH_SERVICES_REQUEST))
    }

    useEffect(onMountHandler, []);

    return {
        services,
        imgFolder,
    }

}

export default useContainer;