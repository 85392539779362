import * as React from "react"
import SvgGradient from "../../components/svgGradient";
import {v4 as uniqueId} from 'uuid';

function Twitter(props) {
    const id = uniqueId()
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 50"
            width={25}
            height={25}
            {...props}
            fill={props.fill ? props.fill : `url(#${id})`}
        >
            <SvgGradient id={id}/>
            <path
                d="M50.063 10.438a20.57 20.57 0 01-5.91 1.62 10.309 10.309 0 004.523-5.687 20.648 20.648 0 01-6.531 2.492 10.258 10.258 0 00-7.504-3.246c-5.68 0-10.286 4.602-10.286 10.281 0 .805.094 1.59.27 2.344-8.547-.43-16.121-4.523-21.195-10.746a10.243 10.243 0 00-1.39 5.172c0 3.566 1.812 6.715 4.573 8.562a10.274 10.274 0 01-4.66-1.289v.13c0 4.984 3.547 9.136 8.246 10.085a10.29 10.29 0 01-4.644.172c1.312 4.082 5.11 7.063 9.605 7.145A20.613 20.613 0 012.39 41.87c-.831 0-1.648-.047-2.449-.144a29.053 29.053 0 0015.762 4.62c18.914 0 29.258-15.667 29.258-29.253 0-.446-.012-.895-.027-1.332a20.904 20.904 0 005.129-5.325z"/>
        </svg>
    )
}

export default Twitter;









