import React from 'react';
import Layout from "../../components/layout";
import MessageContent from "./MassegeContent";
import Map from "./Map";
import Services from "./Services";
import PageTitle from "../../components/helmet";
import "./style.scss";

const Contact = () => {
    return (
        <Layout>
            <PageTitle title='Contact - Timeless'/>
            <div className='contact-page'>
                <MessageContent />
                <Map />
                <Services />
            </div>
        </Layout>
    )
}

export default Contact;