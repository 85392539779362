import * as React from "react"

function VideoPlay(props) {
    return (
        <svg
            width={29}
            height={37}
            viewBox="0 0 29 37"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M0 37V.833l28.417 18.084L0 37z" fill="#fff" />
        </svg>
    )
}

export default VideoPlay;