import React from "react";
import { Form, Input } from 'antd';
import { useField } from "formik";

const InputFiled = ({ label, name, placeholder, formItemClassName, asComponent: Component, ...props }) => {
    const [field, meta] = useField(name);
    const hasError = meta.touched && meta.error;
    const Error = hasError ? <div className="error" style={{marginLeft:15,marginTop:5}}>{meta.error}</div> : undefined;

    return (
        <Form.Item
            className={formItemClassName}
            label={<span>{label}</span>}
            htmlFor={name}
            validateStatus={hasError && 'error'}
            help={Error}

        >
            <Component id={name} placeholder={placeholder} {...field} {...props} />
        </Form.Item>
    );
};

InputFiled.defaultProps = {
    label: '',
    name: '',
    placeholder: '',
    formItemClassName: '',
    asComponent: Input,
}

export default InputFiled;