import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {makeAction} from "../../../state/makeAction";
import {FETCH_REVIEWS_REQUEST, CLEAR_REVIEWS} from "../../../state/general/types";

function useContainer() {
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const {reviews, reviewsPageCount, reviewsLoader} = useSelector(({general}) => general);

    const onMountHandler = () => {
       dispatch(makeAction(FETCH_REVIEWS_REQUEST, page));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(onMountHandler, [page]);

    useEffect(() => {
        return () => {
            dispatch(makeAction(CLEAR_REVIEWS))
        }
    }, [])

    return {
        page,
        setPage,
        reviews,
        reviewsPageCount,
        reviewsLoader,
    }
}

export default useContainer;