import {createLogic} from 'redux-logic';
import {SEND_MESSAGE} from "../types";

const sendMessage = createLogic({
    type: SEND_MESSAGE,
    latest: true,

    async process({httpClient, action}, dispatch, done) {
        try {
            const {data} = await httpClient.post('/send-mail', action.payload);
            action.callback(data.message);

        } catch (e) {
            console.log(e,444);
        }
        done();
    },
});

export default sendMessage;