import React from 'react';
import {isEmpty} from "lodash";
import Layout from "../../components/layout";
import ServiceCard from "../../components/serviceCard";
import Quote from "../../components/quote";
import {citiesThree, citiesTwo} from './serviceConstants/index'
import TopContent from "../../components/topContent";
import PageTitle from "../../components/helmet";
import useContainer from "./hook";
import createImageUrl from "../../utils/createImageUrl";
import Loader from "../../components/loader";
import './style.scss';

const Service = () => {
    const {services, imgFolder} = useContainer();
    return (
        <Layout>
            {isEmpty(services) ? <Loader/> :
                <>
                    <PageTitle title='Service-Timeless'/>
                    <div>
                        <div className='main-body'>
                            <TopContent
                                image={createImageUrl('intro_image', imgFolder, services?.intro_image)}
                                title={services.title}
                                text={services.intro_text}
                            />
                            <div className='main-container'>
                                <div className='carts-container'>
                                    {services.categories && services?.categories.map((item, index) =>
                                        <ServiceCard
                                            key={index}
                                            imageHeight={250}
                                            {...item}
                                            logo={true}
                                            folder={imgFolder}
                                        />)}
                                </div>
                                <h1>CITIES WE SERVICE</h1>
                                <div className='cities-container'>
                                    <ul>
                                        {citiesThree[0].map((item, index) => <li key={index}>{item}</li>)}
                                    </ul>
                                    <ul>
                                        {citiesThree[1].map((item, index) => <li key={index}>{item}</li>)}
                                    </ul>
                                    <ul>
                                        {citiesThree[2].map((item, index) => <li key={index}>{item}</li>)}
                                    </ul>
                                </div>


                                <div className='cities-container2'>
                                    <ul>
                                        {citiesTwo[0].map((item, index) => <li key={index}>{item}</li>)}
                                    </ul>
                                    <ul>
                                        {citiesTwo[1].map((item, index) => <li key={index}>{item}</li>)}
                                    </ul>
                                </div>
                            </div>
                            <Quote/>
                        </div>
                    </div>
                </>
            }
        </Layout>
    )
}

export default Service;