import React from 'react';
import './style.scss'

const About = ({icon, title, text}) => {
    return (
        <div className='aboutContent'>
            {icon}
            <h2 className='textAbout'>{title}</h2>
            <p>{text}</p>
        </div>
    )
}

export default About;