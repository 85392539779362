import React from 'react';
import solar1 from "../../../assets/images/solar1.png";
import solar2 from "../../../assets/images/solar2.png";
import solar3 from "../../../assets/images/solar3.png";
import solar4 from "../../../assets/images/solar4.png";
import Layout from "../../../components/layout";
import ServiceChild from "../../../components/serviceChild";
import Roofing from "../../../assets/svges/roofing";
import roofingImage from "../../../assets/images/roofing.jpg";
import TopContent from "../../../components/topContent";
import PageTitle from "../../../components/helmet";

const SolarSystem = () => {
    return (
        <Layout>
            <PageTitle title='Solar System – Timeless'/>
            <TopContent
                image={roofingImage}
                title='ROOFING'
                text='We are ready to help you with your roof maintenance, repair and full replacement.
                    We guarantee free inspection and provide with exceptional results. '
            />
            <ServiceChild>
                <div className='logoContainer'>
                    <div className='logoMargin'>
                        <Roofing fill='#B6812A' width={97} height={91}/>
                    </div>
                </div>
                <h5>Solar System </h5>
                <p>
                    With the constant increase in energy costs in the USA, it is time to prepare your home or business for
                    the future. Solar energy is sufficient and costs less than regular electricity. Get started on the road
                    to energy self-sufficiency today. With everybody going green, solar panels are becoming a popular
                    choice.
                </p>
                <p>
                    Solar panels (arrays of photovoltaic cells) make use of renewable energy from the sun. On a bright sunny
                    day, the sun’s rays give off approximately 1,000 watts of energy per square meter of the planet’s
                    surface. If we could collect all that energy, we could easily power our homes and offices for free.
                </p>
                <p>
                    The solar panels send electrical currents to your home power inverter. From your inverter, the
                    electrical current is sent to your power panel box for your use. The electrical currents that are not
                    used by your home are credited by the power company back to you through your meter which counts
                    backwards.
                </p>
                <div className='kitchenBottomContainer'>
                    <img src={solar1} alt="#" className='kitchenBottom'/>
                </div>
                <p>
                    We help residential, commercial, industrial, and institutional clients to reduce energy costs and
                    generate on-site electricity using solar energy. Our goal is to provide the best possible energy
                    solutions to every customer using high-quality materials at the latest technology.
                </p>
                <div className='kitchenBottomContainer'>
                    <img src={solar2} alt="#" className='kitchenBottom'/>
                </div>
                <p>
                    There are several steps to follow when planning to power your home with solar energy.
                </p>
                <ul>
                    <li>Investigate your home's energy efficiency</li>
                    <li>Assess your solar potential and any limitations</li>
                    <li>Estimate your solar electricity needs</li>
                    <li>Understand available financing and incentives</li>
                </ul>
                <h6>1.Investigate Your Home's Energy Efficiency</h6>
                <p>
                    Before starting the process of powering your home with solar energy, homeowners should investigate their
                    energy use and consider potential efficiency upgrades. Explore the following resources to reduce your
                    electricity use: Home energy audits, appliances and electronics, lighting, heating and cooling
                </p>
                <h6>2. Assess Your Solar Potential and any limitations</h6>
                <p>
                    Before deciding on the best way to use solar electricity at home, we will assess the potential solar
                    energy that can be produced at your address.
                </p>
                <p>
                    At Timeless Construction we provide an accurate assessment of your solar potential as well as detailed
                    recommendations, estimates, and equipment expertise, considering the nearby shade trees, the age of your
                    roof and how long until it will need to be replaced.
                </p>
                <h6>3. Estimate Your Solar Electricity Needs</h6>
                <p>
                    Our professional team will gather information about your home and electricity use, review electricity
                    bills to provide recommendations for your system’s type, size and determine annual electricity needs.
                </p>
                <p>
                    The project manager will also consider any planned changes whether you will be purchasing an electric
                    vehicle or are planning a home addition.
                </p>
                <h6>4. Understand Available Financing and Incentives</h6>
                <p>
                    Be sure to explore all the available solar financing options we offer at Timeless Construction. Every
                    situation is different, and what is best for your property depends on a wide range of factors.
                </p>
                <div className='twoImages'>
                    <img src={solar3} alt="#"/>
                    <img src={solar4} alt="#"/>
                </div>
                <p>
                    At Timeless Construction we will determine the appropriate size for your system. The size will be based
                    on your electricity needs as well as the following: The site's solar resource or available sunlight, the
                    system's orientation and tilt, the system's efficiency at converting sunlight to electricity, other
                    electricity sources, like a utility, a wind turbine, or a fossil fuel generator.
                </p>
                <p>
                    We will also ensure that all equipment is installed correctly, oriented and tilted in such a way to
                    maximize the daily and seasonal solar energy received and produced by your system.
                </p>
            </ServiceChild>
        </Layout>
    );
};

export default SolarSystem;