import {createLogic} from 'redux-logic';
import {FETCH_SERVICES_REQUEST, FETCH_SERVICES_SUCCESS} from "../types";
import {makeAction} from "../../makeAction";

const fetchServices = createLogic({
    type: FETCH_SERVICES_REQUEST,
    latest: true,

    async process({httpClient}, dispatch, done) {
        try {
            const {data} = await httpClient.get('/services');
            dispatch(makeAction(FETCH_SERVICES_SUCCESS, data));
        } catch (e) {
            console.log(e);
        }
        done();
    },
});

export default fetchServices;