import * as React from "react"
import SvgGradient from "../../components/svgGradient";
import {v4 as uniqueId} from 'uuid';

function Phone(props) {
    const id = uniqueId()
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 140.7 150.91"
            width={16}
            height={16}
            {...props}
        >
            <SvgGradient id={id}/>
            <g data-name="\u0421\u043B\u043E\u0439 2" fill={props.fill ? props.fill : `url(#${id})`}>
                <path

                    d="M52.89 44.4c8.38-9.73-.74-22.52-6.35-31.23C43.37 8.12 38.71 1 32.15.05c-6.24-.68-11.89 6.25-16 10.06a47.8 47.8 0 00-16 34.09c.07 1.53.07 4-.15 1.75C1 54 1.89 61.59 4.74 69.2a98.8 98.8 0 0010.31 20.16c8.86 13.38 19.1 26.33 31.27 37.06 11.85 10.54 25.19 20 41 23.39 14.83 3 29.3-.13 41-9.74 4.23-3.46 12-8.49 12.35-14.48s-4.48-10.88-8.6-14.51c-8.82-7.89-21.79-21-33.78-11.43-4.67 3.83-9.63 10.92-15.89 11.92s-12.22-3.48-16.7-7C55.69 96.34 45.89 85.22 40 73.6c-2.48-5.18-4.71-11.83-1.06-17s9.93-7.44 13.95-12.2z"
                    data-name="\u0421\u043B\u043E\u0439 1"
                />
            </g>
        </svg>
    )
}

export default Phone
