import React from 'react';
import './style.scss'
import roofing from '../../../assets/images/kitchen.jpg'
import kitchen from '../../../assets/images/kitchen.jpg'
import plumbing from '../../../assets/images/plumging.jpg'
import bathroom from '../../../assets/images/bathRoom.png'
import {Carousel} from "react-bootstrap";
import Roofing from "../../../assets/svges/roofing";
import Plumbing from "../../../assets/svges/plumbing";
import Bathroom from "../../../assets/svges/bathroom";
import Kitchen from "../../../assets/svges/kitchen";

const BottomSlider = () => {
    const menuItems = [
        {
            title: 'ROOFING',
            img: roofing,
            icon: <Roofing className='icon' width={120} height={120} />,
            text: 'We are ready to help you with your roof maintenance, repair and full replacement. We guarantee free inspection and provide with exceptional results.'
        },
        {
            title: 'PLUMBING',
            img: plumbing,
            icon: <Plumbing className='icon' width={120} height={120} />,
            text: 'We can help you with all your plumbing needs from installing water heater or filtration system to repiping your entire house or replacing the sewer line. Contact us for detailed free estimate and quality work.'
        },
        {
            title: 'BATHROOM',
            icon: <Bathroom className='icon' width={120} height={120} />,
            img: bathroom,
            text: 'We’ll be happy to help with all your bathroom remodeling needs.From changing the faucets t converting your bathroom into an in-home spa. Contact us for detailed approach and free project plan.'
        },
        {
            title: 'KITCHEN',
            icon: <Kitchen className='icon' width={120} height={120} />,
            img: kitchen,
            text: 'We’re willing to help with all your kitchen remodeling needs. Whether that\'s installing equipment or redesigning your entire kitchen, we do it all. To get a free itemized estimate and quality-conscious approach, feel free to contact us.'
        }
    ]
    return (
        <div className='bottomCarousel'>
            <Carousel controls={false}>
                {
                    menuItems.map((el, index) => (
                                <Carousel.Item key={index}>
                                    <img
                                        className="d-block w-100"
                                        src={el.img}
                                        alt="First slide"
                                    />
                                    <Carousel.Caption>
                                        <div> {el.icon}</div>
                                        <span>{el.title}</span>
                                        <p>{el.text}</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                        )
                    )
                }
            </Carousel>
            <div className='line'/>
        </div>
    )
}

export default BottomSlider;
