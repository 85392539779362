import React from "react";
import Yelps from "../assets/svges/yelps";
import Google from "../assets/svges/google";
import House from "../assets/svges/house";
import Facebook from "../assets/svges/facebook";
import Inst from "../assets/svges/inst";
import Twitter from "../assets/svges/twitter";
import Pinterest from "../assets/svges/pinterest";
import TikTok from "../assets/svges/tiktok";

export const SOCIAL_MEDIA_ICONS = ({fill, width, height}) => [
    {
        icon: <Yelps className='social-icon' width={width} height={height} fill={fill}/>,
        link:'https://www.yelp.com/biz/timeless-construction-san-fernando'
    },
    {
        icon: <Google className='social-icon' width={width} height={height} fill={fill}/>,
        link:'https://www.google.com/maps/place/Timeless+Construction/@34.2805949,-118.4330208,15z/data=!4m5!3m4!1s0x0:0xfb133c93b3b68451!8m2!3d34.2805949!4d-118.4330208'
    },
    {
        icon: <House className='social-icon' width={width} height={height} fill={fill} />,
        link:'https://www.houzz.com/professionals/general-contractors/timeless-construction-pfvwus-pf~1866635988?'
    },
    {
        icon: <Facebook className='social-icon' width={width} height={height} fill={fill} />,
        link:'https://www.facebook.com/timelessconstr'

    },
    {
        icon: <Inst className='social-icon' width={width} height={height} fill={fill} />,
        link:'https://www.instagram.com/timelessconstr/'

    },
    {
        icon: <Twitter className='social-icon' width={width} height={height} fill={fill} />,
        link:'https://twitter.com/TimelessConstr'
    },
    {
        icon: <Pinterest className='social-icon' width={width} height={height} fill={fill} />,
        link:'https://www.pinterest.com/timelessconstruction/'
    },
    {
        icon: <TikTok className='social-icon' width={width} height={height} fill={fill} />,
        link:'https://www.tiktok.com/@timelessconstr'
    }
];