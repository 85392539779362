import servicesOperations from "../services/operations";
import generalOperations from "../general/operations";
import typesOperations from "../projectTypes/operations";
import galleryOperations from "../gallery/operations";

const rootOperations = [
    ...servicesOperations,
    ...generalOperations,
    ...typesOperations,
    ...galleryOperations,
];

export default rootOperations;