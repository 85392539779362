import * as React from "react"
import SvgGradient from "../../components/svgGradient";
import {v4 as uniqueId} from 'uuid';

function MarkerIcon(props) {
    const id = uniqueId()
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 135.42 172.1"
            width={16}
            height={16}
            {...props}
        >
            <SvgGradient id={id}/>
            <g data-name="\u0421\u043B\u043E\u0439 2" fill={props.fill ? props.fill : `url(#${id})`}>
                <path

                    d="M62.29 0c36.74-.46 57.28 16.14 68 40.57 4 9.06 7.28 24.85 3.54 37.19C126.09 103.12 111.4 121.25 97 140c-4.9 6.37-10.28 12-15.22 18.26-1.65 2.09-4.36 3.67-6 5.75s-6 7.79-8.85 8.12c-2-2.9-5.31-4.77-7.43-7.45-4.91-6.17-11.1-11.4-15.93-17.58-9.07-11.57-18.42-23-26.55-35.5-3.39-5.2-5.54-10.55-8.49-16.22C1.63 82.15-3.4 62.9 2.83 45a72.93 72.93 0 0110.61-19.63C21 15.36 32.89 7.68 46 3.05c3.87-1.36 8.36-1.78 12.39-2.7 1.37-.32 3 .22 3.9-.34zm1.41 21.65c-.85.49-2.26 0-3.54.34-3.1.74-7.06 1.37-9.91 2.7-11.18 5.24-19.7 13-24.78 24-1.44 3.15-2 7.09-2.82 10.48-1.42 5.87.72 14.59 2.12 18.26C29.9 90.94 37.41 99.06 51 104.48c3.89 1.55 13.16 4.71 19.47 3.38 3.69-.78 7.89-.82 11.32-2 13-4.56 22.55-13.23 28-25 4-8.79 3.37-22.16 0-30.76C102.87 32.6 89 21.56 63.7 21.65z"
                    fillRule="evenodd"
                    data-name="\u0421\u043B\u043E\u0439 1"
                />
            </g>
        </svg>
    )
}

export default MarkerIcon;
