import React, {useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import {categoriesPages} from "../../constants/routes";
import SingleRoof from "./SingleRoof";
import {useDispatch} from "react-redux";
import {makeAction} from "../../state/makeAction";
import {FETCH_CATEGORIES_REQUEST} from "../../state/projectTypes/types";

const Category = () => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const type = searchParams.get('type');
    const Component = categoriesPages?.[type] || SingleRoof;

    useEffect(() => {
        dispatch(makeAction(FETCH_CATEGORIES_REQUEST, type))
    }, [type])

    return (
        <Component/>
    )
};

export default Category;