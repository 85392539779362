import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import SeeMore from "../../../assets/svges/seeMore";
import Cards from "./cards";
import useContainer from "./hook";
import {Spin} from "antd";
import './style.scss'

const CardContent = () => {
    const {page, setPage, reviews, reviewsPageCount, reviewsLoader} = useContainer();
    return (
        <div className='mainCardContent'>
            <div className='cards'>{reviews.map(el => <Cards key={el.time} {...el}/>)}</div>
            <div className='seeMore'>
                {reviewsLoader ? <Spin indicator={<LoadingOutlined style={{ fontSize: 80, color: '#f1cc6c' }} spin />} /> :
                    (reviewsPageCount !== page) && <SeeMore onClick={() => setPage(page + 1)}/>
                }
            </div>
        </div>
    )
}
export default CardContent;