import React, {useEffect} from "react";
import {useLocation, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Layout from "../../components/layout";
import {isEmpty} from "lodash";
import TypeWithChildren from "./TypeWithChildren";
import {makeAction} from "../../state/makeAction";
import {FETCH_TYPE_REQUEST} from "../../state/projectTypes/types";
import TypeWithOutChildren from "./TypeWithOutChildren";
import Loader from "../../components/loader";

const Type = () => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const {id} = useParams();
    const {types, imgFolder} = useSelector(({types}) => types);

    useEffect(() => {
        if(pathname.split('/')[1] === 'services') dispatch(makeAction(FETCH_TYPE_REQUEST, id));
    }, [id, pathname])

    return (
        <Layout>
            {isEmpty(types) ? <Loader/> :
                isEmpty(types.categories) ?
                    <TypeWithOutChildren types={types} imgFolder={imgFolder}/> :
                    <TypeWithChildren types={types} imgFolder={imgFolder}/>}
        </Layout>
    )
}

export default Type;