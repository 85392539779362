import { useEffect, useMemo } from 'react';

const createGoogleMapsOverlay = (container, pane, position) => {
  class Overlay extends window.google.maps.OverlayView {
    // eslint-disable-next-line no-shadow
    constructor(container, pane, position) {
      super();
      this.container = container;
      this.pane = pane;
      this.position = position;
    }
    onAdd() {
      const pane = this.getPanes()?.[this.pane]
      pane?.appendChild(this.container)
    }

    draw() {
      const projection = this.getProjection()
      const point = projection.fromLatLngToDivPixel(this.position)
      if (point === null) {
        return
      }
      this.container.style.transform = `translate(${point.x}px, ${point.y}px)`
    }
    onRemove() {
      if (this.container.parentNode !== null) {
        this.container.parentNode.removeChild(this.container)
      }
    }
  }
  return new Overlay(container, pane, position);
};

function useContainer({ position, pane, map, zIndex }) {
  const container = useMemo(() => {
    const element = document.createElement('div');
    element.style.position = 'absolute';

    return element;
  }, []);

  const overlay = useMemo(
    () => createGoogleMapsOverlay(container, pane, position),
    [container, pane, position],
  );

  const setOverlayMapHandler = () => {
    if (!overlay || !map) {
      return;
    }
    overlay.setMap(map);
    // window.google.maps.event.trigger(map, 'resize');
    return () => overlay.setMap(null);
  };

  const setZIndex = () => {
    container.style.zIndex = `${2000}`;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(setOverlayMapHandler, [map, overlay]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(setZIndex, [zIndex, container]);

  return {
    container,
    overlay,
    setOverlayMapHandler,
    setZIndex,
  };
}

export default useContainer;
