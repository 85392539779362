import {useFormik} from "formik";
import {useDispatch} from "react-redux";
import {makeAction} from "../state/makeAction";
import {SEND_MESSAGE} from "../state/general/types";
import validationSchema from "../lib/yupLocalised/scheme/contactUsMessage";

function useFormikHook() {
    const dispatch = useDispatch();

    const onSubmit = (values) => {
        const formData = new FormData();
        Object.entries(values).map(([key, value]) => {
            formData.append(key, value);
        });

        dispatch(makeAction(SEND_MESSAGE, formData, (success) => {
          if(success) formik.resetForm();
        }));
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            phone: '',
            email: '',
            message: '',
        },
        validationSchema,
        onSubmit,
    });

    return {
        onSubmit,
        formik,
    }
}

export default useFormikHook;