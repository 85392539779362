import React from 'react';
import './style.scss'

const Introduction = () => {

    return (
        <div className='introductionContent'>
            <div className='introduction'>
                <h3>INTRODUCTION</h3>
                <div className='introductionText'>
                    <p>
                        Timeless construction has been in business since 1998, providing service for residential,
                        commercial and industrial properties. We pride ourselves on delivering quality results for
                        everything we do. Whether we are fixing a roof, building a new roof or repiping your house. Our
                        professional and experienced team members will provide the best quality service making sure to
                        deliver stress-free experience. That includes free inspection of the job, handling each step and
                        completing the project in a timely manner.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Introduction;