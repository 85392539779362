import React from 'react';
import {Link} from "react-router-dom";
import Roofing from "../../assets/svges/roofing";
import createImageUrl from "../../utils/createImageUrl";
import './style.scss';

const ServiceSmallCard = (
    {
        imageFileName,
        slug,
        parentId,
        imageClassName,
        bigCircleClassName,
        smallCircleClassName,
        title,
        circleWithText,
        cardClassName,
        text,
        imgFolder,
        image,
        redirectParrentRoute,
    }
) => {
    return (
        <div className={`service-small-card-container ${cardClassName}`}>
            <div className='imageContainer'>
                <>
                    <div
                        style={{backgroundImage: `url(${createImageUrl(imageFileName, imgFolder, image)})`}}
                        className={`image ${imageClassName}`}
                    >
                        <div className='color'/>
                    </div>
                    {circleWithText ? (
                        <div className={`bigCircle ${bigCircleClassName}`}>
                            <div className={`circle ${smallCircleClassName}`}>
                                <Roofing width={56} height={40}/>
                                <span className='title gradient-text'>{title}</span>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className={`bigCircle ${bigCircleClassName}`} style={{backgroundColor: 'initial'}}>
                                <div className={`circle ${smallCircleClassName}`}><Roofing width={56} height={40}/></div>
                            </div>
                            <p className='with-auth-circle-title'>{title}</p>
                        </>
                    )
                    }

                </>
            </div>
            {text &&
                <div className='text-content'>
                    <span className='text'>{text}</span>
                </div>
            }
            <Link to={{
                pathname: `/${redirectParrentRoute}/${parentId}/category`,
                search: `?type=${slug}`
            }}>
                <button className='view-more-button'>View more</button>
            </Link>
        </div>
    )
}

export default ServiceSmallCard;