import {createLogic} from 'redux-logic';
import {FETCH_REVIEWS_REQUEST, FETCH_REVIEWS_SUCCESS} from "../types";
import {makeAction} from "../../makeAction";

const reviewsList = createLogic({
    type: FETCH_REVIEWS_REQUEST,
    latest: true,

    async process({httpClient, action}, dispatch, done) {
        try {
            const {data} = await httpClient.get(`/reviews`, {params: {page: action.payload}});
            dispatch(makeAction(FETCH_REVIEWS_SUCCESS, data))
        } catch (e) {
            console.log(e);
        }
        done();
    },
});

export default reviewsList;