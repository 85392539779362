import timelessConstruction from "../../../assets/images/timelessConstruction.png";
import plumbing from "../../../assets/images/plumbing.jpg";
import metal3 from "../../../assets/images/metal3.png";
import ourTeam from "../../../assets/images/ourTeam.png";

function useContainer() {
    const menuItems = [
        {
            title: 'TIMELESS CONSTRUCTION',
            img: timelessConstruction,
            text: 'Welcome to Timeless Construction- leading construction company that can help you with all your roofing, plumbing and remodeling needs. We take pride for quality homes that stand the test of time. We offer integrity and customer satisfaction.'
        },
        {
            title: 'SERVICES',
            img: plumbing,
            text: 'Our plumbing solutions include sewer line repair and repiping services. In addition to roofing and plumbing, our company also provides bathroom remodeling and kitchen remodeling services.'
        },
        {
            title: 'TIMELESS CONSTRUCTION',
            img: metal3,
            text: 'To provide these services, each of our team members spend time doing thorough research to identify the problem in the house, find its source and then come up with a solution. '
        },
        {
            title: 'TIMELESS CONSTRUCTION',
            img: ourTeam,
            text: 'We also let our customers know about the steps we will take when solving the problem and let them know that we will always be there to answer questions and address further issues.'
        }
    ]

    return {
        menuItems,
    }
}

export default useContainer;