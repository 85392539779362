import React, {useEffect} from "react";
import {useLocation, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Layout from "../../components/layout";
import {isEmpty} from "lodash";
import TypeWithChildren from "./TypeWithChildren";
import {makeAction} from "../../state/makeAction";
import {FETCH_GALLERY_TYPE_REQUEST} from "../../state/projectTypes/types";
import TypeWithOutChildren from "./TypeWithOutChildren";
import Loader from "../../components/loader";

const GalleryTypes = () => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const {id} = useParams();
    const {galleryImageFolder, galleryType} = useSelector(({types}) => types);

    useEffect(() => {
        dispatch(makeAction(FETCH_GALLERY_TYPE_REQUEST, id));
    }, [id, pathname])

    return (
        <Layout>
            {isEmpty(galleryType) ? <Loader/> :
                isEmpty(galleryType.categories) ?
                    <TypeWithOutChildren types={galleryType} imgFolder={galleryImageFolder}/> :
                    <TypeWithChildren types={galleryType} imgFolder={galleryImageFolder}/>}
        </Layout>
    )
}

export default GalleryTypes;