import React from "react";
import Header from "../header";
import Footer from "../footer";
import {SOCIAL_MEDIA_ICONS} from "../../constants";
import "./style.scss";

const Layout = ({children}) => (
    <div>
        <div className='socialContent'>
            <div className='socialIcons'>
                {SOCIAL_MEDIA_ICONS({fill: '#707070', width: 23, height: 27}).map((el, index) =>
                    <a href={el.link} target="_blank" rel="noreferrer" key={index}>{el.icon}</a>)}
            </div>
        </div>
        <Header/>
        <div className='layout-content'>{children}</div>
        <Footer/>
    </div>
)

export default Layout;