import * as React from "react"
import SvgGradient from "../../components/svgGradient";
import {v4 as uniqueId} from 'uuid';

function Resource(props) {
    const id = uniqueId()
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 161.43 200.79"
            {...props}
        >
            <SvgGradient id={id}/>
            <path className="cls-1"
                  fill={props.fill ? props.fill : `url(#${id})`}
                  d="M139.71 0H35.86a21.74 21.74 0 00-21.72 21.72V32H5.51a5.51 5.51 0 000 11h8.63v51.89H5.51a5.51 5.51 0 100 11h8.63v51.9H5.51a5.51 5.51 0 100 11h8.63v10.26a21.74 21.74 0 0021.72 21.72h103.85a21.75 21.75 0 0021.72-21.72V21.72A21.75 21.75 0 00139.71 0zm10.72 179.07a10.72 10.72 0 01-10.68 10.69H35.86a10.72 10.72 0 01-10.68-10.69v-10.26h8.63a5.51 5.51 0 000-11h-8.68V105.9h8.63a5.51 5.51 0 100-11h-8.63V43h8.63a5.51 5.51 0 100-11h-8.63V21.72A10.73 10.73 0 0135.82 11h103.89a10.73 10.73 0 0110.68 10.69zm-62.65-76.94A24.28 24.28 0 1070.59 95a24.27 24.27 0 0017.19 7.13zm-9.4-33.7a13.3 13.3 0 11-3.88 9.4 13.3 13.3 0 013.88-9.4zm9.4 37.43a35.71 35.71 0 00-35.67 35.67 5.5 5.5 0 005.51 5.47H118a5.51 5.51 0 005.5-5.51 35.7 35.7 0 00-35.72-35.63zM63.75 136a24.66 24.66 0 0148.08 0z"
            />
        </svg>
    )
}

export default Resource
