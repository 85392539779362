import React from "react";
import {Link} from "react-router-dom";
import TimelessLogoGrey from "../../assets/svges/timelessLogoGrey";
import Phone from "../../assets/svges/phone";
import MarkerIcon from "../../assets/svges/mrkerIcon";
import Email from "../../assets/svges/email";
import {SOCIAL_MEDIA_ICONS} from "../../constants";
import './style.scss'

const Footer = () => (
    <div className='footerContent'>
        <div className='footer'>
            <div className='contacts'>
                <Link to='/home'><TimelessLogoGrey/></Link>
                <ul className='first'>
                    <li><MarkerIcon fill={'#838383'} className='icon'/><span>13739 Ventura Blvd Sherman Oaks,CA 91423</span>
                    </li>
                    <li><Phone fill={'#838383'} className='icon'/><span>(424) 384 4444</span></li>
                    <a id="emailMe" href="mailto:example@website.com?subject=This%20is%20a%20subject">
                        <li><Email fill={'#838383'} className='icon'/><span>info@timelessconstructiongroup.com</span> </li>
                    </a>

                </ul>
            </div>
            <div className='partsMobile'>
                <ul>
                    <Link to={'/home'}> <li>Home</li></Link>

                    <li>Plumbing</li>
                    <li>Kitchen</li>
                </ul>
                <ul>
                    <li>About Us</li>
                    <li>Roofing</li>
                    <li>Gallery</li>
                </ul>
                <ul>
                    <li>Service</li>
                    <li>Bathroom</li>
                    <li>Contact Us</li>
                </ul>

            </div>
            <div className='parts'>
                <ul className='second'>
                    <Link to={'/home'}>  <li>Home Page</li></Link>
                    <li>About Us</li>
                    <li>
                        Service
                        <ul>
                            <li>Plumbing</li>
                            <li>Roofing</li>
                            <li>Bathroom</li>
                            <li> Kitchen</li>
                        </ul>
                    </li>
                    <li>Gallery</li>
                    <li>Contact Us</li>
                </ul>
            </div>
            <div className='notice'>
                <p>
                    Welcome to Timeless Construction- leading construction company that can help you with all your
                    roofing, plumbing and remodeling needs. We take pride for quality homes that stand the test of
                    time. We offer integrity and customer satisfaction.
                </p>
                <div className='logos'>

                    {
                        SOCIAL_MEDIA_ICONS({fill: '#707070', width: 16, height: 16}).map((el, index) =>
                            <a href={el.link} target="_blank" rel="noreferrer" key={index}>{el.icon}</a>)
                    }
                </div>
            </div>
        </div>
        <div className='construction'>
            <h2>© TIMELESS CONSTRUCTION 2022</h2>
        </div>
    </div>
)

export default Footer;
