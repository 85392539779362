import React from 'react';
import water1 from "../../../assets/images/waterf1.png";
import water2 from "../../../assets/images/waterf2.png";
import water3 from "../../../assets/images/waterf3.png";
import plumbingImage from "../../../assets/images/plumbing.jpg";
import Layout from "../../../components/layout";
import ServiceChild from "../../../components/serviceChild";
import Plumbing from "../../../assets/svges/plumbing";
import TopContent from "../../../components/topContent";
import PageTitle from "../../../components/helmet";

const WaterFiltrationService = () => {
    return (
        <Layout>
            <PageTitle title='Water Filtration Systems – Timeless'/>
            <TopContent
                image={plumbingImage}
                title='PLUMBING'
                text="We can help you with all your plumbing needs from installing water heater or
                    filtration system to repiping your entire house or replacing the sewer lin.
                    Contact us for detailed free estimate and quality work."
            />
            <ServiceChild>
                <div className='logoContainer'>
                    <div className='logoMargin'>
                        <Plumbing fill='#B6812A' width={97} height={91}/>
                    </div>
                </div>
                <h5>Water Filtration System</h5>
                <div className='kitchenBottomContainer'>
                    <img src={water1} alt="#" className='kitchenBottom'/>
                </div>
                <p>
                    Well and city water can contain a range of contaminants. Chlorine is a commonly used water treatment
                    method to kill things like parasites, bacteria and viruses. But, when used in higher doses, chlorine can
                    leave behind it’s signature foul taste and odor in your drinking water. Or you might also experience
                    dry, itchy skin when you get out of the shower.
                </p>
                <div className='twoImages'>
                    <img src={water2} alt="#"/>
                    <img src={water3} alt="#"/>
                </div>
                <p>
                    With a whole house water filtration you can enjoy the peace of mind that your filtration system is
                    reducing sediment, dirt and debris from your water, while also reducing levels of chlorine or other
                    disinfection byproducts. Timeless Construction offers two types of water filtration reverse osmosis
                    system for drinking water only and whole house filtration.
                </p>
            </ServiceChild>
        </Layout>
    );
};

export default WaterFiltrationService;