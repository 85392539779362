import React from "react";
import withGoogleMaps from "../../../hooks/withGoogleMaps";
import MapMarker from "../../../components/mapMarker";
import MapTopSvg from "../../../assets/svges/mapTopSvg";
import MarkerIcon from "../../../assets/svges/mrkerIcon";
import useContainer from "./hook";
import "./style.scss";

const Map = () => {
    const {map, mapContainerRef} = useContainer();

    return (
        <div className='map'>
            <div className='top-icon'><MarkerIcon width={43} height={54}/></div>
            <div className='top-content'><MapTopSvg width={145} height={51} fill={'#f8f8f8'}/></div>
            <div className='map-container' ref={mapContainerRef}/>
            <MapMarker
                location={{latitude: 34.28081015375896, longitude: -118.43305027095289}}
                text={'address'}
                map={map}
            />
        </div>
    )
};

export default withGoogleMaps(Map);