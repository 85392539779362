import * as React from "react"

function UserIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 154.57 154.7"
            {...props}
        >
            <g data-name="\u0421\u043B\u043E\u0439 2">
                <path
                    d="M131.93 100a76.86 76.86 0 00-29.37-18.44 44.68 44.68 0 10-50.56 0A77.46 77.46 0 000 154.7h12.07a65.21 65.21 0 11130.42 0h12.08a76.86 76.86 0 00-22.64-54.7zM77.28 77.35a32.63 32.63 0 1132.61-32.63 32.66 32.66 0 01-32.61 32.63z"
                    data-name="\u0421\u043B\u043E\u0439 1"
                />
            </g>
        </svg>
    )
}

export default UserIcon
