import SingleRoof from "../pages/Categories/SingleRoof";
import TileRoof from "../pages/Categories/TileRoof";
import FlatRoof from "../pages/Categories/FlatRoof";
import MetalRoof from "../pages/Categories/MetalRoof";
import FoamRoof from "../pages/Categories/FoamRoof";
import SolarSystem from "../pages/Categories/SolarSystem";
import RepipingService from "../pages/Categories/RepipingService";
import SewerLineService from "../pages/Categories/SewerLineService";
import WaterHeater from "../pages/Categories/WaterHeater";
import WaterFiltrationService from "../pages/Categories/WaterFiltrationService";

export const categoriesPages = {
    'SINGLE-ROOF744': SingleRoof,
    'TILE-ROOF': TileRoof,
    'FLAT-ROOF': FlatRoof,
    'METAL-ROOF': MetalRoof,
    'FOAM-ROOF': FoamRoof,
    'SOLAR-SYSTEM': SolarSystem,
    'Repiping-Service': RepipingService,
    'Sewer-line-service': SewerLineService,
    'WATER-HEATER-INSTALLATION': WaterHeater,
    'WATER-FILTRATION-SYSTEMS': WaterFiltrationService,
};