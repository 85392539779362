import React from "react";
import {v4 as uniqueId} from "uuid";
import Star from "../../assets/svges/star";
import "./style.scss";

const activeColors = {
    1: '#ca9d4c',
    2: '#cda251',
    3: '#d8b162',
    4: '#dfbb6d',
    5: '#e3c072',
}

const StarRating = ({rate, size, defaultColor}) => {
    const activeCount = Math.floor(rate);
    const restCount = 5 - activeCount;
    return (
        <div className='stars'>
            {new Array(activeCount).fill('').map((_, index) =>
                <span className="icon" key={uniqueId()}>
                    <Star fill={activeColors[index + 1]} width={size} height={size}/>
                </span>)}
            {new Array(restCount).fill('').map(() =>
                <span className="icon" key={uniqueId()}>
                    <Star fill={defaultColor} width={size} height={size}/>
                </span>)}
        </div>
    )
};

export default StarRating;