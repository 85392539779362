import React from 'react';
import StarRating from "../../../../components/starRating";
import Google from "../../../../assets/svges/google";
import './style.scss'

const Cards = ({text, author_name, profile_photo_url, relative_time_description, rating, author_url}) => (
    <div className='cardContent'>
        <div className='cardTop'>
            <div className='avatarContent'>
                <img src={profile_photo_url} referrerPolicy="no-referrer" alt=""/>
                <div className='avatarDes'>
                    <h3>{author_name}</h3>
                    <p>{relative_time_description[0]} month</p>
                </div>
            </div>
        </div>
        <p>{`${text.split(' ').slice(0, 12).join(' ')}...`}</p>
        <div className='rating'>
            <div className='starSvg'><StarRating rate={rating} size={21} defaultColor={'#b1b1b1'} /></div>
            <a href={author_url} target="_blank" rel="noreferrer"><Google fill={'#b1b1b1'}/></a>
        </div>
    </div>
)
export default Cards;