import * as types from "./types";

const initialState = {
    gallery: {},
    imgFolder: '',
}

const galleryReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_GALLERY_SUCCESS:
            return { ...state, gallery: action.payload.data[0], imgFolder: action.payload.imgFolder }
        default:
            return state;
    }
};

export default galleryReducer;