import React from "react";
import AboutDes from "../../../components/aboutDesContent";
import ServiceSmallCard from "../../../components/serviceSmallCard";
import {SOCIAL_MEDIA_ICONS} from "../../../constants";
import useContainer from "./hook";
import "./style.scss";

const Services = () => {
    const { data } = useContainer();
    return (
        <div className='services'>
            <div className='content'>
                <h2 className='title'>SERVICES WE OFFER</h2>
                <div className='service-cards'>
                    {data.map((item, index) => (
                        <ServiceSmallCard
                            cardClassName='service-card'
                            bigCircleClassName='big-circle-className'
                            smallCircleClassName='small-circle-className'
                            imageClassName='card-image'
                            circleWithText
                            width={264}
                            height={284}
                            key={index}
                            {...item}
                        />
                    ))}
                </div>
                <div className='social-icons'>
                    {
                        SOCIAL_MEDIA_ICONS({width: 23, height: 27}).map((item, index) => (
                            <div key={index} className='icons'>
                                <a href={item.link} target="_blank" rel="noreferrer">{item.icon}</a>
                            </div>
                        ))
                    }
                </div>
                <AboutDes aboutTopBorder styles={{borderBottom: 'none', backgroundColor: '#f8f8f8', marginTop: 95}}/>
            </div>
        </div>
    )
};

export default Services;
