import React from 'react';
import metal1 from "../../../assets/images/metal1.png";
import metal2 from "../../../assets/images/metal2.png";
import metal3 from "../../../assets/images/metal3.png";
import metal4 from "../../../assets/images/metal4.png";
import roofingImage from "../../../assets/images/roofing.jpg";
import Layout from "../../../components/layout";
import ServiceChild from "../../../components/serviceChild";
import Roofing from "../../../assets/svges/roofing";
import TopContent from "../../../components/topContent";
import PageTitle from "../../../components/helmet";

const MetalRoof = () => {
    return (
        <Layout>
            <PageTitle title='Metal Roof – Timeless'/>
            <TopContent
                image={roofingImage}
                title='ROOFING'
                text='We are ready to help you with your roof maintenance, repair and full replacement.
                    We guarantee free inspection and provide with exceptional results. '
            />
            <ServiceChild>
                <div className='logoContainer'>
                    <div className='logoMargin'>
                        <Roofing fill='#B6812A' width={97} height={91}/>
                    </div>
                </div>
                <h5>Metal roof</h5>
                <p>
                    A metal roof is one of the best investments you can make to boost your home’s value. Delivering beauty,
                    longevity, energy efficiency and environmental sustainability—metal roofing offers benefits for any size
                    home in every region of the country. Residential metal roofs are available in a wide variety of styles,
                    types and colors. They can make a bold architectural statement or effortlessly complement any home and
                    neighborhood.
                    When configured and installed by Timeless Construction, metal roofing can last as long as fifty years or
                    more and, in most cases, requires very little maintenance over the course of its lifespan. So even
                    though the initial cost of metal roof is usually higher than asphalt, metal roofing can end up saving
                    you time and money in the long run. Plus, most metal roofing products are offered with true lifetime
                    warranties of 30 to 50+ years.
                </p>
                <div className='twoImages'>
                    <img src={metal1} alt="#"/>
                    <img src={metal2} alt="#"/>
                </div>
                <p>
                    While your neighbors may re-roof their home many times, a high-quality metal roof will likely be the
                    last roof you'll ever need. Resistant to cracking, shrinking and eroding, metal roofing systems can also
                    withstand extreme weather conditions including heavy snow loads, hailstorms, and wildfires requiring
                    minimal upkeep.
                    Ensuring your metal roof is free from debris and a once-yearly inspection for minor maintenance items
                    such as cleaning and paint touch up is typically all that is needed to keep your metal roof in tip-top
                    shape. Contact Timeless Construction for your metal roof project, we are fully licensed, bonded and
                    insured with over 20 years of experience.
                </p>
                <div className='twoImages'>
                    <img src={metal3} alt="#"/>
                    <img src={metal4} alt="#"/>
                </div>
            </ServiceChild>
        </Layout>
    );
};

export default MetalRoof;