import * as React from "react"
import SvgGradient from "../../components/svgGradient";
import { v4 as uniqueId } from 'uuid';

function Google(props) {
const id = uniqueId()
    return (
        <svg
            viewBox="0 0 24 24"
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
            width={25}
            height={25}
            {...props}
            fill={props.fill? props.fill :`url(#${id})`}
        >
            <SvgGradient id={id}/>
            <path
                d="M23.735 9.5H12v5h6.486C17.478 17.122 14.977 19 12 19a7 7 0 010-14c1.402 0 2.694.43 3.789 1.14.167.11.34.212.497.334l3.637-3.47-.082-.063A11.93 11.93 0 0012 0C5.373 0 0 5.373 0 12s5.373 12 12 12c6.118 0 11.155-4.581 11.896-10.498.063-.492.104-.993.104-1.502 0-.858-.094-1.693-.265-2.5z"
            />
            <path
                d="M15.789 5.89A6.932 6.932 0 0012 4.75a7 7 0 00-6.999 7.117A6.994 6.994 0 0112 5c1.402 0 2.694.43 3.789 1.14.167.11.34.212.497.334l3.637-3.47-3.637 3.22c-.157-.122-.33-.224-.497-.333z"
                opacity={0.1}
            />
            <path
                d="M12 .25c2.975 0 5.683 1.098 7.78 2.892l.143-.138-.11-.096A11.829 11.829 0 0012 0C5.373 0 0 5.373 0 12c0 .042.006.083.006.125C.074 5.555 5.415.25 12 .25z"
                opacity={0.2}
            />
        </svg>
    )
}

export default Google
