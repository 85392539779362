import * as React from "react"

function RightNav(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={21.909}
            height={45.383}
            viewBox="0 0 21.909 45.383"
            {...props}
        >
            <defs>
                <linearGradient
                    id="a"
                    x1={-0.11}
                    y1={0.5}
                    x2={1.11}
                    y2={0.5}
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset={0} stopColor="#af7822" />
                    <stop offset={1} stopColor="#f1cc6c" />
                </linearGradient>
            </defs>
            <path
                data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 6 \u043A\u043E\u043F\u0438\u044F 5"
                d="M1411.894 991.839l-1.47-1.684a1.318 1.318 0 010-1.683l16.559-18.976-16.559-18.976a1.318 1.318 0 010-1.683l1.47-1.684a.948.948 0 011.469 0l18.363 21.045a3.861 3.861 0 010 2.6l-18.363 21.044a.947.947 0 01-1.469 0z"
                transform="translate(-1410.12 -946.805)"
                fill="url(#a)"
            />
        </svg>
    )
}

export default RightNav
