import React from 'react';
import Layout from "../../components/layout";
import './style.scss'
import ServiceCard from "../../components/serviceCard";
import Quote from "../../components/quote";
import PageTitle from "../../components/helmet";
import createImageUrl from "../../utils/createImageUrl";
import TopContent from "../../components/topContent";
import useContainer from "./hook";

const Gallery = () => {
    const {gallery, imgFolder} = useContainer();
    console.log(gallery)
    return (
        <Layout>
            <PageTitle title='Gallery - Timeless'/>
            <div style={{background: '#f1f1f1', display: 'flex', flexDirection: 'column'}}>
                <TopContent
                    contentClassName='galleryTitle'
                    image={createImageUrl('cover_image', imgFolder, gallery?.cover_image)}
                    title={gallery.title}
                />
                <div className='galleryMid'>
                    <div className='galleryCards'>
                        {gallery?.categories?.map((item, index) =>
                            <ServiceCard
                                fileName='images'
                                id={item?.id}
                                key={index}
                                imageHeight={250}
                                folder={imgFolder}
                                title={item?.title}
                                intro_image={item?.images?.[0]}
                            />)}
                    </div>
                </div>
                <Quote/>
            </div>
        </Layout>
    );
};

export default Gallery;