import React from 'react';
import wHeater1 from "../../../assets/images/wHeater1.png";
import wHeater2 from "../../../assets/images/wHeater2.png";
import wHeater3 from "../../../assets/images/wHeater3.png";
import plumbingImage from "../../../assets/images/plumbing.jpg";
import Layout from "../../../components/layout";
import ServiceChild from "../../../components/serviceChild";
import Plumbing from "../../../assets/svges/plumbing";
import TopContent from "../../../components/topContent";
import PageTitle from "../../../components/helmet";

const WaterHeater = () => {
    return (
        <Layout>
            <PageTitle title='Water Heater Installation – Timeless'/>
            <TopContent
                image={plumbingImage}
                title='PLUMBING'
                text="We can help you with all your plumbing needs from installing water heater or
                    filtration system to repiping your entire house or replacing the sewer lin.
                    Contact us for detailed free estimate and quality work."
            />
            <ServiceChild>
                <div className='logoContainer'>
                    <div className='logoMargin'>
                        <Plumbing fill='#B6812A' width={97} height={91}/>
                    </div>
                </div>
                <h5>Water Heater</h5>
                <p>
                    Your water heater is one of the most important appliances in your home. You depend on it for your daily
                    activities like cooking, cleaning, and bathing. Also, your washing machine
                    and dishwasher depend on it too.
                    At timeless Construction we offer high-quality water heater installation services which are customized
                    to your home and your system’s needs. You can depend on us whether it is a small tank installation or a
                    series of large water heaters for a large home. We will make sure the systems are installed and set up
                    to work their absolute best. We know the importance of water heater; therefore, we make your
                    installation as fast and as dependable as possible. At Timeless Construction we
                    provide variety of water heater services.
                </p>
                <div className='kitchenBottomContainer'>
                    <img src={wHeater1} alt="#" className='kitchenBottom'/>
                </div>
                <h5>Tank-type heater</h5>
                <p>
                    Tank-type heater is the most common type of water heater and has been around for over a hundred years.
                    They cost less upfront and are the easiest to install and replace, but later can cost you more money in
                    energy because the tanks are large and heavy. When it’s time to replace your water heater, we will
                    remove the old unit and install the new one wherever you’d like.
                </p>
                <div className='kitchenBottomContainer'>
                    <img src={wHeater2} alt="#" className='kitchenBottom'/>
                </div>
                <h5>Tankless Water Heater</h5>
                <p>
                    With this type of water heater you will enjoy faster flowing and instant hot water flow. Because it
                    heats water as you use it, you can never run out of hot water. The higher upfront cost of a tankless
                    system is offset by energy savings.
                </p>
                <div className='kitchenBottomContainer'>
                    <img src={wHeater3} alt="#" className='kitchenBottom'/>
                </div>
                <h5>Hot water heater boosters </h5>
                <p>
                    Installation of this device can help to make water heaters more efficient.
                </p>
                <p>
                    Contact Timeless Construction for water heater replacement as soon as you notice any of these signs:
                    your current water heater is leaking, it is showing signs of age, your energy bills climbed
                    substantially for no apparent reason, you seem to run out of hot water faster, than you did before, or
                    water doesn’t seem as hot as it was before.
                </p>
                <p>
                    A new water heater installation is a serious project, which is why we strongly advise leaving it to our
                    professional team. We make sure you have the proper permits for getting the job done, that your
                    connections are solid and sealed from leaks and other issues and that your new heater is properly
                    secured.
                </p>
            </ServiceChild>
        </Layout>
    );
};

export default WaterHeater;