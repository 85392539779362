import React from "react";
import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";

const Loader = () => (
    <div style={{width: '100%', height: '30vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 80, color: '#f1cc6c' }} spin />} />
    </div>
)

export default Loader;