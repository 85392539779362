import fetchTypes from "./fetchTypes";
import fetchCategories from "./fetchCategories";
import fetchGalleryTypes from "./fetchGalleryTypes";

const typesOperations = [
    fetchTypes,
    fetchCategories,
    fetchGalleryTypes,
];

export default typesOperations;