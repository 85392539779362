import React from 'react';
 import './style.scss'
import {Link} from "react-router-dom";
import NotFoundSvg from "../../assets/svges/notFound";

const NotFound = () => {
    return (
        <div className='notFoundContainer'>
            <div className='notFoundView'>
                <div>
                    <NotFoundSvg/>
                </div>
            </div>
            <div className='backHome'>
                <Link to={'/home'}>
                    <button>BACK TO HOME</button>
                </Link>
            </div>
        </div>
    );
};

export default NotFound;