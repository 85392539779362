import {createLogic} from 'redux-logic';
import {FETCH_TYPE_SUCCESS, FETCH_TYPE_REQUEST} from "../types";
import {makeAction} from "../../makeAction";

const fetchTypes = createLogic({
    type: FETCH_TYPE_REQUEST,
    latest: true,

    async process({httpClient, action}, dispatch, done) {
        try {
            const {data} = await httpClient.get(`/services/children?id=${action.payload}`);
            dispatch(makeAction(FETCH_TYPE_SUCCESS, data));
        } catch (e) {
            console.log(e);
        }
        done();
    },
});

export default fetchTypes;