import * as React from "react"

function MapTopSvg(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={176.517}
            height={62.255}
            viewBox="0 0 176.517 62.255"
            {...props}
        >
            <path
                data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 2 \u043A\u043E\u043F\u0438\u044F 6"
                d="M467.293 2924.324H643.81l-89.01 62.256h1.5l-89.008-62.255z"
                transform="translate(-467.293 -2924.324)"
            />
        </svg>
    )
}

export default MapTopSvg
