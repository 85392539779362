import React from 'react';
import Send from "../../assets/svges/send";
import AboutDes from "../aboutDesContent";
import { Form, Input} from "antd";
import {FormikProvider} from "formik";
import InputFiled from "../forms/inputField";
import useFormikHook from "../../hooks/useFormikHook";
import './style.scss'

const Quote = () => {
    const {formik} = useFormikHook();

    return (
        <div className='quote'>
            <h2>GET A FREE QUOTE</h2>
            <div className='quoteContent'>
                <p>Our customer service is happy to help. Please fill out the form below and will get back to you
                    promptly
                    regarding your request.</p>
                <Form onFinish={formik.handleSubmit}>
                    <FormikProvider value={formik} className='inputContent'>
                        <div className='quote-inputs'>
                            <InputFiled
                                className="quote-input"
                                name="name"
                                placeholder='Full Name'
                                formItemClassName="inputContent"
                                bordered={false}
                            />
                            <InputFiled
                                className="quote-input"
                                name="phone"
                                placeholder='Phone Number'
                                formItemClassName="inputContent"
                                bordered={false}
                            />
                            <InputFiled
                                className="quote-input"
                                name="email"
                                placeholder='Email'
                                formItemClassName="inputContent"
                                bordered={false}
                            />
                        </div>

                        <div className='textAreaContent'>
                            <InputFiled
                                name="message"
                                placeholder='Text message'
                                formItemClassName="formItem"
                                asComponent={Input.TextArea}
                                bordered={false}
                                rows={3}
                                autosize={{minRows: 2, maxRows: 3}}
                            />
                            <button type='submit' className='send'>
                                <Send/>
                            </button>
                        </div>
                    </FormikProvider>
                    <p className='text'>
                        Company Name not sell, share, or trade customer information. Your privacy is very important
                        to us.
                    </p>
                </Form>
                <button className='sendButton'>SEND</button>
            </div>
            <div className='quoteMidLine'></div>
            <AboutDes/>
        </div>
    );
};

export default Quote