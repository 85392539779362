import {createLogic} from 'redux-logic';
import {makeAction} from "../../makeAction";
import {FETCH_GALLERY_REQUEST, FETCH_GALLERY_SUCCESS} from "../types";

const fetchGallery = createLogic({
    type: FETCH_GALLERY_REQUEST,
    latest: true,

    async process({httpClient}, dispatch, done) {
        try {
            const {data} = await httpClient.get('/gallery');
            dispatch(makeAction(FETCH_GALLERY_SUCCESS, data));
        } catch (e) {
            console.log(e);
        }
        done();
    },
});

export default fetchGallery;