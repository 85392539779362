import React from 'react';
import './style.scss'
import Roofing from "../../assets/svges/roofing";
import Plumbing from "../../assets/svges/plumbing";
import Bathroom from "../../assets/svges/bathroom";
import Kitchen from "../../assets/svges/kitchen";


const Introduction = () => {
    const menuItems = [
        {
            title: 'ROOFING',
            icon: <Roofing className='icon' width={90} height={90} />,
        },
        {
            title: 'PLUMBING',
            icon: <Plumbing className='icon' width={90} height={90} />,
        },
        {
            title: 'BATHROOM',
            icon: <Bathroom className='icon' width={90} height={90} />,
        },
        {
            title: 'KITCHEN',
            icon: <Kitchen className='icon' width={90} height={90} />,
        }
    ]
    return (
        <div className='menuContent'>
            <div className='menu'>
                {
                    menuItems.map((el, index) => {
                        return <div key={index} className='menuItems'
                                    style={index < 3 ? {borderRight: '2px solid #e0e0e0'} : null}>
                            {el.icon}
                            <span>{el.title}</span>
                        </div>
                    })
                }
            </div>
        </div>
    )
}

export default Introduction;
