import React from 'react';
import flat1 from "../../../assets/images/flat1.png";
import flat2 from "../../../assets/images/flat2.png";
import flat3 from "../../../assets/images/flat3.png";
import roofingImage from "../../../assets/images/roofing.jpg";
import Layout from "../../../components/layout";
import ServiceChild from "../../../components/serviceChild";
import Roofing from "../../../assets/svges/roofing";
import TopContent from "../../../components/topContent";
import PageTitle from "../../../components/helmet";

const FlatRoof = () => {
    return (
       <Layout>
           <PageTitle title='Flat Roof – Timeless'/>
           <TopContent
               image={roofingImage}
               title='BATHROOM'
               text='Well be happy to help with all your bathroom remodeling needs.
                From changing the faucet converting your bathroom into an in-home spa.
                Contact us for detailed approach and free project plan.'
           />
           <ServiceChild>
               <div className='logoContainer'>
                   <div className='logoMargin'>
                       <Roofing fill='#B6812A' width={97} height={91}/>
                   </div>
               </div>
               <h5>Flat roof </h5>
               <p>
                   Flat roofs offer multiple advantages to residential and commercial properties. They are easier to
                   install and repair than sloping roofs. Flat roofs may offer extra usable space on top of the
                   building. Another benefit of torch down (flat) roofing is that it provides an amazing protection
                   against heat, while adding some extra coats of material generates a fire-resistant layer. This type
                   of roofing also protects your home from UV rays and is free of harmful fumes. Torch down is a highly
                   durable option that can last up to 20 years or even longer and is probably the best choice for homes
                   in areas with harsh weather.It is called torch down roofing because of the technique that is used to
                   apply it. Torch down roofing is available in two-layer and three-layer options.
               </p>
               <div className='kitchenBottomContainer'>
                   <img src={flat1} alt="#" className='kitchenBottom'/>
               </div>
               <p>
                   There is a chance of catching fire when using live fire during the installation. To avoid this kind
                   of hazards the project must be handled by a professional. Contact Timeless Construction for your
                   flat roof project, we are fully licensed, bonded and insured with over 20 years of experience.
               </p>
               <div className='twoImages'>
                   <img src={flat2} alt="#"/>
                   <img src={flat3} alt="#"/>
               </div>
           </ServiceChild>
       </Layout>
    );
};

export default FlatRoof;