import * as React from "react"
import SvgGradient from "../../components/svgGradient";
import {v4 as uniqueId} from 'uuid';

function House(props) {
    const id = uniqueId()
    return (
        <svg
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
            width={25}
            height={25}
            {...props}
            fill={props.fill ? props.fill : `url(#${id})`}

        >
            <SvgGradient id={id}/>
            <path
                d="M308.981 332.702h-107.4v153.297H43.148V26.001h112.433V133.3l313.271 87.893V486h-159.87V332.702z"
            />
        </svg>
    )
}

export default House
