import useMapLocation from "../../../hooks/useMapLocation";

function useContainer() {
    const { map, mapContainerRef } = useMapLocation({latitude: 34.28081015375896, longitude: -118.43305027095289});
    return {
        map,
        mapContainerRef,
    }
}

export default useContainer;