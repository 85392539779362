import React from "react";
import TopContent from "../../../components/topContent";
import ServiceChild from "../../../components/serviceChild";
import Roofing from "../../../assets/svges/roofing";
import ServiceSmallCard from "../../../components/serviceSmallCard";
import PageTitle from "../../../components/helmet";
import createImageUrl from "../../../utils/createImageUrl";

const TypeWithChildren = ({types, imgFolder}) => {
    return (
        <>
            <PageTitle title='Roofing – Timeless'/>
            <div className='service-roofing-page'>
                <TopContent
                    image={createImageUrl('cover_image', imgFolder, types?.cover_image)}
                    title={types?.title}
                    text={types?.description}
                />
                <ServiceChild>
                    <div className='logoContainer'>
                        <div className='logoMargin'><Roofing width={97} height={91}/></div>
                    </div>
                    <div className='information-container'>
                        <h5 className='title'>{types?.title}</h5>
                        <p className='text text-align-center'>{types?.short_description}</p>
                        <p className='text text-align-center'>{types?.intro_text}</p>
                        <p className='text-bold text-align-center'>
                            We service residential and commercial buildings and experience working
                            with a large range of products, such as:
                        </p>
                        <div className='types-container'>
                            <h5 className='types-content-title'>SERVICES WE PROVIDE</h5>
                            {types?.categories?.length > 4 ? (
                                    <div className='type-cards-small'>
                                        {types?.categories?.map((item, index) => (
                                            <ServiceSmallCard
                                                cardClassName='type-card'
                                                imageClassName='card-image'
                                                bigCircleClassName='big-circle'
                                                smallCircleClassName='small-circle'
                                                key={index}
                                                imgFolder={imgFolder}
                                                parentId={types.id}
                                                imageFileName='images'
                                                image={item?.images[0]}
                                                redirectParrentRoute='gallery'
                                                {...item}
                                            />
                                        ))}
                                    </div>
                                ) :
                                (
                                    <div className='type-cards-big'>
                                        {types?.categories?.map((item, index) => (
                                            <ServiceSmallCard
                                                parentId={types.id}
                                                cardClassName='type-card'
                                                imageClassName='card-image'
                                                bigCircleClassName='big-circle'
                                                smallCircleClassName='small-circle'
                                                key={index}
                                                imgFolder={imgFolder}
                                                imageFileName='images'
                                                image={item?.images[0]}
                                                redirectParrentRoute='gallery'
                                                {...item}
                                            />
                                        ))}
                                    </div>
                                )}

                        </div>
                    </div>
                </ServiceChild>
            </div>
        </>
    )
};

export default TypeWithChildren;