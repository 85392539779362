import React from "react";
import Roofing from "../../../assets/svges/roofing";
import Plumbing from "../../../assets/svges/plumbing";
import Bathroom from "../../../assets/svges/bathroom";
import Kitchen from "../../../assets/svges/kitchen";
import roofImage from "../../../assets/images/roofing.png";
import plumbingImage from "../../../assets/images/plumbing.png";
import bathRoomImage from "../../../assets/images/bathRoomImage.png";
import kitchenImage from "../../../assets/images/kitchenImage.png";

const data = [
    {
        image: roofImage,
        title: 'ROOFING',
        icon: <Roofing width={56} height={40}/>
    },
    {
        image: plumbingImage,
        title: 'PLUMBING',
        icon: <Plumbing width={56} height={40}/>
    },
    {
        image: bathRoomImage,
        title: 'BATHROOM ',
        icon: <Bathroom width={56} height={40}/>,
    },
    {
        image: kitchenImage,
        title: 'KITCHEN',
        icon: <Kitchen width={56} height={40}/>,
    },
]

function useContainer() {

    return {
        data,
    }
}

export default useContainer;
