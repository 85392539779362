import React from 'react';
import Quote from "../quote";
import './style.scss'

const ServiceChild = ({children}) => (
    <div className='service-child-main-container'>
        <div className='service-child-container'>
            <div className='children-container'>{children}</div>
        </div>
        <Quote/>
    </div>
)

export default ServiceChild;