import React from 'react';
import {Dropdown} from "antd";
import {Link, NavLink} from "react-router-dom";
import {CaretDownOutlined} from "@ant-design/icons";
import MobileHeader from "../mobileHeader";
import TimelessLogo from "../../assets/svges/timelessLogo";
import useContainer from "./hook";
import 'antd/dist/antd.min.css';
import './style.scss';

const Header = () => {
    const {menu, activeUnderLine, dropDownVisible, handleDropDownOpen, dropDownMenu} = useContainer();
    return (
        <>
            <div className='header'>
                <div className='header-content'>
                    <Link className='logo' to='/home'>
                        <TimelessLogo width={135} height={71}/>
                    </Link>
                    <div className='nav-bar'> {menu.map(item => (Boolean(item.children.length) ?
                            <Dropdown
                                key={item.path + item.name}
                                onOpenChange={(e) => handleDropDownOpen(e, item.name)}
                                open={dropDownVisible?.[item.name] || false}
                                menu={{items: dropDownMenu(item?.children)}}
                                className='drop-down'
                                icon={<CaretDownOutlined />}
                            >
                                <div className="navLinkItem drop-down-nav-link">
                                    <NavLink to={item.path}
                                             className={() => (activeUnderLine === item.path || dropDownVisible?.[item.name]) ?
                                                 'active-nav-link' : 'nav-link'}
                                    >
                                <span>
                                    {item.name}
                                    <CaretDownOutlined style={{marginLeft: 4, marginTop: 5, fontSize: 17}} />
                                </span>
                                    </NavLink>
                                </div>
                            </Dropdown>
                            :
                            <NavLink key={item.path + item.name} to={item.path}
                                     className={() => activeUnderLine === item.path ? 'active-nav-link' : 'nav-link'}
                            >
                                {item.name}
                            </NavLink>

                    ))}
                    </div>
                    <div className='license'>LICENSE #1034265</div>
                </div>
            </div>
            <MobileHeader menu={menu} dropDownMenu={dropDownMenu}/>
        </>
    );
};

export default Header;
