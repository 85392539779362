import React from 'react';
import repiping1 from "../../../assets/images/repipingSer1.png";
import repiping2 from "../../../assets/images/repipingSer2.png";
import Layout from "../../../components/layout";
import ServiceChild from "../../../components/serviceChild";
import Plumbing from "../../../assets/svges/plumbing";
import plumbingImage from "../../../assets/images/plumbing.jpg";
import TopContent from "../../../components/topContent";
import PageTitle from "../../../components/helmet";

const RepipingService = () => {
    return (
       <Layout>
           <PageTitle title='Repiping Service – Timeless'/>
           <TopContent
               image={plumbingImage}
               title='PLUMBING'
               text="We can help you with all your plumbing needs from installing water heater or
                    filtration system to repiping your entire house or replacing the sewer lin.
                    Contact us for detailed free estimate and quality work."
           />
           <ServiceChild>
               <div className='logoContainer'>
                   <div className='logoMargin'>
                       <Plumbing fill='#B6812A' width={97} height={91}/>
                   </div>
               </div>
               <h5>Repiping Service</h5>
               <p>
                   Pipes, generally, can last very long time. However, if you live in an older house, you could find
                   yourself in need of repiping. Or if it is a new house with damaged or poorly installed plumbing, you
                   don’t want to put off necessary repairs. Leaks and pipe failure can cause significant damage.
               </p>
               <p>
                   If you know you need repiping services, contact Timeless Construction right away. Our professional team
                   will solve the problem in the quickest and the most efficient manner providing with quality results.
               </p>
               <div className='kitchenBottomContainer'>
                   <img src={repiping1} alt="#" className='kitchenBottom'/>
               </div>
               <p>
                   Old or damaged pipes can cause problems like mold, damage to your home foundation, pipe breakage, leaks,
                   flooding and most importantly it can cause damage to your family’s health. The best way to avoid these
                   concerns is to have annual plumbing inspection by our professional and licensed team.
               </p>
               <div className='kitchenBottomContainer'>
                   <img src={repiping2} alt="#" className='kitchenBottom'/>
               </div>
               <p>The signs you need to pay attention on for pipe repair include:</p>
               <ul>
                   <li>Cracks in your wall paint (when leak is so small there might not be a visible stain)</li>
                   <li>Visible water stains</li>
                   <li>Unusual high water bill</li>
                   <li>Musty smell of mold or mildew from walls</li>
                   <li>Water meter moving after you’ve turned the water off</li>
               </ul>
               <p>The signs you need to pay attention on when you need new pipes include:</p>
               <ul>
                   <li>Unusual high water bill</li>
                   <li>Rumbling sound near your water main</li>
                   <li>Clanging and grinding noises</li>
                   <li>Unpleasant odors</li>
                   <li>Lower water pressure</li>
                   <li>Rusty or brown colored water</li>
               </ul>
               <p>
                   If you notice any of these signs contact Timeless Construction for free
                   investigation of the source of your issues.
               </p>
           </ServiceChild>
       </Layout>
    );
};

export default RepipingService;