import React from 'react';
import Layout from "../../components/layout";
import './style.scss'
import Quote from "../../components/quote";
import TeamCard from "./teamCard";
import PageTitle from "../../components/helmet";
import TopContent from "../../components/topContent";
import teamBackground from '../../../src/assets/images/temaBackground.png'
const Team = () => {
    const arr = [
        {
            name: 'AJ M.',
            profession: 'PROJECT MENGER',
            description: 'Ed is the founder of Timeless Construction. He has over 20 years of experience providing excellent home improvement services in Los Angeles. He founded TC on the idea to provide excellent customer service and quality home services.'
        },
        {
            name: 'AJ M.',
            profession: 'PROJECT MENGER',
            description: 'Ed is the founder of Timeless Construction. He has over 20 years of experience providing excellent home improvement services in Los Angeles. He founded TC on the idea to provide excellent customer service and quality home services.'
        },
        {
            name: 'AJ M.',
            profesion: 'PROJECT MENGER',
            description: 'Ed is the founder of Timeless Construction. He has over 20 years of experience providing excellent home improvement services in Los Angeles. He founded TC on the idea to provide excellent customer service and quality home services.'
        },
        {
            name: 'AJ M.',
            profession: 'PROJECT MENGER',
            description: 'Ed is the founder of Timeless Construction. He has over 20 years of experience providing excellent home improvement services in Los Angeles. He founded TC on the idea to provide excellent customer service and quality home services.'
        },
        {
            name: 'AJ M.',
            profession: 'PROJECT MENGER',
            description: 'Ed is the founder of Timeless Construction. He has over 20 years of experience providing excellent home improvement services in Los Angeles. He founded TC on the idea to provide excellent customer service and quality home services.'
        },
        {
            name: 'AJ M.',
            profession: 'PROJECT MENGER',
            description: 'Ed is the founder of Timeless Construction. He has over 20 years of experience providing excellent home improvement services in Los Angeles. He founded TC on the idea to provide excellent customer service and quality home services.'
        },
    ]
    return (
        <Layout>
            <PageTitle title='Team - Timeless'/>
            <TopContent
                contentClassName='ourTeamTop'
                image={teamBackground}
                title='OUR TEAM'
                text="We are team of experts coming to work every day to serve all your construction needs and
                    provide exceptional results. Our professional contractors will accurately measure and
                    estimate your project needs. Our management paired with QA (Quality assessment)
                    team will ensure the job is done correctly and in timely manner."
            />
            <div className='teamLine'></div>
            <div className='teamCardContent'>
                <div className='teamCards'>
                    {
                        arr.map((el, index) => <TeamCard key={index} {...el}/>)
                    }
                </div>
                <p className='teamDes'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                    labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra
                    maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
                    Risus commodo viverra maecenas accumsan lacus vel facilisis.
                </p>
                <Quote/>
            </div>
        </Layout>
    );
};

export default Team;