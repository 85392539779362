import React from "react";
import {CloseOutlined, MenuOutlined} from "@ant-design/icons";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import TimelessLogo from "../../assets/svges/timelessLogo";
import {SOCIAL_MEDIA_ICONS} from "../../constants";
import useContainer from "./hook";
import "./style.scss";

const MobileHeader = ({menu}) => {
    const {mobileMenuVisible, onMenuOpenChange, items, setOpenItems, openItems} = useContainer({menu});

    return (
        <div className='mobile-header'>
            <div className='header-content'>
                <div className='license-content'>
                    <div className='license'>LICENSE #1034265</div>
                </div>
                <div className='center-content'>
                    <Link to='/home'>
                        <TimelessLogo width={135} height={71}/>
                    </Link>
                </div>
                <div className='menu-button-content'>
                    {mobileMenuVisible ?
                        <CloseOutlined className="mobile-menu-icon" onClick={onMenuOpenChange}/> :
                        <MenuOutlined className="mobile-menu-icon" onClick={onMenuOpenChange}/>}
                </div>
            </div>
            {mobileMenuVisible &&
                <>
                    <div className='background-modal' onClick={onMenuOpenChange}/>
                    <div className='menu-content'>
                        <Menu
                            onOpenChange={(e) => setOpenItems(e)}
                            className='mobile-header-menu'
                            defaultSelectedKeys={openItems}
                            mode='inline'
                            theme='light'
                            items={items(menu)}

                        />
                        <div className='social-icons'>
                            {
                                SOCIAL_MEDIA_ICONS({fill: '#707070', width: 23, height: 27}).map((item, index) => (
                                    <div key={index} className='icons'>
                                        <a href={item.link} target="_blank" rel="noreferrer">{item.icon}</a>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </>
            }
        </div>
    )
};

export default MobileHeader;