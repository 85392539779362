import React from "react";
import {Carousel} from "antd";
import { v4 as uniqueId } from 'uuid';
import TopContent from "../../../components/topContent";
import useContainer from "./hook";
import 'antd/dist/antd.min.css';
import "./style.scss";

const TopSlider = () => {
    const {menuItems} = useContainer();
    return (
        <div className='top-slider'>
            <Carousel>
                {menuItems.map(({img, title, text}, index) => (
                        <TopContent
                            key={uniqueId()}
                            title={title}
                            image={img}
                            text={text}
                            buttonText='FREE ESTIMATE'
                            redirectRoute="#"
                            backgroundClassName='padding-content'
                        />
                    )
                )}
            </Carousel>
        </div>
    )
}

export default TopSlider;