import React from 'react';
import tile1 from "../../../assets/images/tile1.png";
import tile2 from "../../../assets/images/tile2.png";
import tile3 from "../../../assets/images/tile3.png";
import tile4 from "../../../assets/images/tile4.png";
import roofingImage from "../../../assets/images/roofing.jpg";
import Layout from "../../../components/layout";
import ServiceChild from "../../../components/serviceChild";
import Roofing from "../../../assets/svges/roofing";
import TopContent from "../../../components/topContent";
import PageTitle from "../../../components/helmet";

const TileRoof = () => {
    return (
        <Layout>
            <PageTitle title='Tile Roof – Timeless'/>
            <TopContent
                image={roofingImage}
                title='ROOFING'
                text='We are ready to help you with your roof maintenance, repair and full replacement.
                    We guarantee free inspection and provide with exceptional results. '
            />
            <ServiceChild>
                <div className='logoContainer'>
                    <div className='logoMargin'>
                        <Roofing fill='#B6812A' width={97} height={91}/>
                    </div>
                </div>
                <h5>Tile roof</h5>
                <p>
                    Tile roof is known for its ultimate coverage in terms of sheer durability. Tile roofs are especially
                    popular in dry climates like Los Angeles. The most common options are clay and concrete tiles. Concrete
                    tiles are more affordable in cost and installation; however, clay options are way more durable, lasting
                    as long as 75 or even 100 years. Clay tiles are traditionally made of curved clay, but with advances in
                    roofing technology, they now come in all shapes and sizes.
                </p>
                <div className='twoImages'>
                    <img src={tile1} alt="#"/>
                    <img src={tile2} alt="#"/>
                </div>
                <p>
                    Tile roofing can be expensive since they’re a significantly stronger and longer-lasting material than
                    shingles. However, it is well worth the initial investment, because a properly installed tile roof can
                    last for decades. And if you have a tile roof that needs maintenance, you will need a roofing company
                    that has a strong portfolio of tile installations. Cutting tiles to hips and valleys is difficult and
                    only someone professional can handle it, especially if your roof has a chimney or solar panels. Here at
                    Timeless Construction, we have a team of experienced roofers and contractors who have collectively
                    installed, maintained, and repaired thousands of tile roofs over the past two decades. We’re dedicated
                    to providing you with a tile roofing experience that will leave you away with a strong peace of mind.
                </p>
                <div className='twoImages'>
                    <img src={tile3} alt="#"/>
                    <img src={tile4} alt="#"/>
                </div>
            </ServiceChild>
        </Layout>
    );
};

export default TileRoof;