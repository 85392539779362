import React from "react";
import {chunk} from 'lodash'


const cities = [
    'Burbank', 'Canoga Park', 'Chatsworth', 'Encino', 'Glandale', 'Granada Hills', 'La Crescenta', 'Los Angeles', 'Mission Hills', 'Newhall', 'North Hills', 'North Hollywood',
        'Northridge', 'Pacific Palisades', 'Pacoima', 'Panorama City', 'Porter Ranch', 'Reseda', 'San Fernando', 'Santa Clarita', 'Sherman Oaks', 'Studio City', 'Sun Valley', 'Sunland',
    'Sylmar', 'Tarzana', 'Toluca Lake', 'Topanga', 'Universal City', 'Valencia', 'Valley Village', 'Van Nuys', 'West Hills', 'West Hollywood', 'Winnetka', 'Woodland Hills','dasd'
]

export const citiesThree = chunk(cities, Math.ceil(cities.length/3) )
export const citiesTwo = chunk(cities, Math.ceil(cities.length/2) )


