import React, {useRef, useState} from "react";
import VideoPlay from "../../assets/svges/videoPlay";
import "./style.scss";

const VideoComponent = ({video, image}) => {
    const videoRef = useRef(null);
    const [playVisible, setPlayVisible] = useState(true);

    const onPlay = () => {
        videoRef.current.play();
        setPlayVisible(false);
    }

    return (
        <div className='video-component'>
            <div className="bg-video" style={{backgroundImage: `url(${image})`, display: playVisible ? 'flex' : 'none'}}>
                <div className="bt-play" onClick={onPlay}><VideoPlay width={41} height={52} /></div>
            </div>
            <video
                ref={videoRef}
                controls
                style={{objectFit: 'cover', width: '100%', height: '100%'}}
            >
                <source src={video} type="video/mp4"/>
            </video>
        </div>
    )
};

export default VideoComponent;