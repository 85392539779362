import * as React from "react"
import SvgGradient from "../../components/svgGradient";
import {v4 as uniqueId} from 'uuid';

function Convenience(props) {
    const id = uniqueId()
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 223.53 224.74"
            {...props}
        >

            <SvgGradient id={id}/>

            <g data-name="\u0421\u043B\u043E\u0439 2"
               fill={props.fill ? props.fill : `url(#${id})`}>
                <g data-name="\u0421\u043B\u043E\u0439 1">
                    <path
                        d="M100.49 4.56a6.76 6.76 0 017-4.54c10.35 0 20.69-.07 31 0 5.43-.11 8.55 7.13 5 11.13-3.43 4.15-9.39 2.2-14 2.62v9.91A101.3 101.3 0 01154 28.41c3.67-6 6.46-12.56 10.58-18.24 4.56-4.92 13.52.33 11.58 6.71-2.71 6-6.39 11.44-9.15 17.4A100.24 100.24 0 1147.5 190.14a7 7 0 011.17-10.2 7.3 7.3 0 019.84 1.6 86.47 86.47 0 10113.54-129A86.77 86.77 0 0059.38 64.43a7 7 0 01-9.69.9 6.91 6.91 0 01-.64-10 100.65 100.65 0 0166.53-31.59V13.8c-3.35-.07-6.73.27-10.07-.19-3.91-.68-6.64-5.33-5.02-9.05z"/>
                    <path
                        d="M137.76 66.35a6.87 6.87 0 017.39-3.94c4.49.37 7.49 5.88 5.18 9.84-9 17.08-18.42 33.93-27.4 51 9.59 13.19 19 26.5 28.52 39.75 3.25 3.77.7 10.31-4.17 11a6.9 6.9 0 01-7.28-3.1q-15.24-21.39-30.51-42.73a7 7 0 01-.73-7.84c9.54-18.05 19.37-35.98 29-53.98zM20.16 86c17.27-.72 34.62-.07 51.93-.32 3-.18 6.79 0 8.5 3 2.82 4 0 10.46-5 10.67-17.78.1-35.54.05-53.31 0A6.9 6.9 0 0120.16 86zM3.83 117.46c1.6-.85 3.49-.57 5.22-.66h65.37c3.86-.5 7.36 3 7.26 6.85a6.81 6.81 0 01-7.33 6.88c-22.89-.06-45.79.13-68.68-.1-6.47-.61-7.84-10.54-1.84-12.97zM20.52 148.13c12.78-.47 25.59-.07 38.38-.2a6.84 6.84 0 017.2 6.81 6.73 6.73 0 01-7.26 6.93c-12.55-.07-25.11.15-37.65-.1-7.05-.74-7.62-11.95-.67-13.44z"/>
                </g>
            </g>
        </svg>
    )
}

export default Convenience
