import React from 'react';
import sewer1 from "../../../assets/images/sewer1.png";
import sewer2 from "../../../assets/images/sewer2.png";
import sewer3 from "../../../assets/images/sewer3.png";
import sewer4 from "../../../assets/images/sewer4.png";
import sewer5 from "../../../assets/images/sewer5.png";
import Layout from "../../../components/layout";
import ServiceChild from "../../../components/serviceChild";
import Plumbing from "../../../assets/svges/plumbing";
import plumbingImage from "../../../assets/images/plumbing.jpg";
import TopContent from "../../../components/topContent";
import PageTitle from "../../../components/helmet";

const SewerLineService = () => {
    return (
        <Layout>
            <PageTitle title='Sewer Line Repair and Replacement – Timeless'/>
            <TopContent
                image={plumbingImage}
                title='PLUMBING'
                text="We can help you with all your plumbing needs from installing water heater or
                    filtration system to repiping your entire house or replacing the sewer lin.
                    Contact us for detailed free estimate and quality work."
            />
            <ServiceChild>
                <div className='logoContainer'>
                    <div className='logoMargin'>
                        <Plumbing fill='#B6812A' width={97} height={91}/>
                    </div>
                </div>
                <h5>Sewer Line Repair and Replacement</h5>
                <p>
                    Persistent drain issues in your home can signal a bigger sewer pipe problem happening underground.
                    Serious clogging (sewage pipe repair or sewer back up) or other sewer line issue is not always fixed
                    with simple drain cleaning and maintenance. If traditional plumbing techniques do not solve the problem,
                    you must look into replacing the sewer lines and laterals. Sewer drain replacement is a task which
                    requires a professional plumbing crew, and at Timeless Construction, we specialize in identifying and
                    fixing these types of replacements and repairs.
                </p>
                <p>
                    We have a team of experienced drain specialists able to quickly diagnose causes of blockages, such as
                    root intrusion, sewer line breaks, missing sections, and deterioration. Inspection will allow our
                    plumbing techs to find those hidden leaks, fix them and to stop damage and water waste.
                </p>
                <p>
                    With an in-depth sewer camera inspection, our experienced team can identify the cause and perform the
                    ideal sewer line repair and replacement. Common causes of sewer line damage can be
                </p>
                <div className='twoImages'>
                    <img src={sewer1} alt="#"/>
                    <img src={sewer2} alt="#"/>
                </div>
                <p>
                    With an in-depth sewer camera inspection, our experienced team can identify the cause and perform the
                    ideal sewer line repair and replacement. Common causes of sewer line damage can be tree roots that seek
                    out the moisture rich nutrient found in sewer lines. They can enter through even small cracks, and once
                    inside do major damage including breaking the line causing frequent backups. Also poorly maintained
                    drains and heavy use lines can become damaged from hard scale buildup in the pipe. As organic material
                    sits in the line, it can stick to the inside of the pipe and slowly close off drain flow. Scale buildup
                    can also weaken the pipe leading to cracks and breaks or allow roots easy access to the line. Aging
                    lines will eventually deteriorate leading to backups from missing or collapsed sections.
                </p>
                <p>
                    Once we have accurately determined the cause and location of damage with our electronic service, we can
                    recommend the ideal solution to fit your property needs and budget. At Timeless Construction we offer
                    sewer line repair and sewer line replacement.
                </p>
                <div className='kitchenBottomContainer'>
                    <img src={sewer3} alt="#" className='kitchenBottom'/>
                </div>
                <h5>Sewer Line Repair</h5>
                <p>
                    Main sewer line repairs can be performed by routine and professional snaking for minor clogs, hydro
                    jetting for roots or heavy buildup, descaling, coating and with epoxy pipelining.
                </p>
                <p>
                    Professional sewer snaking is typically the first step in diagnosing and repairing a blocked main sewer
                    drain. However, if our drain experts encounter a hard spot, pull back roots, or suspect damage, we will
                    recommend a sewer camera inspection to view the line. With a sewer camera inspection, we can determine
                    next steps in the repair process.
                </p>
                <p>
                    Hydro jetting can remove roots and dissolve heavy sludge or grease buildup. Using high pressure water
                    streams and a multi directional nozzle to scour 360 degrees of the drain, hydro jetting is a safe and
                    effective repair for sewer pipes in good condition.
                </p>
                <p>
                    If the sewer line has cracks, holes, or missing sections, epoxy pipelining may be the best method to
                    rehabilitate the line. Using an epoxy resin and specialty liner, pipelining creates a brand-new pipe
                    inside of the damaged line or section without excavation.
                </p>
                <div className='kitchenBottomContainer'>
                    <img src={sewer4} alt="#" className='kitchenBottom'/>
                </div>
                <p>
                    When aggressive root intrusion has broken a sewer line, or if there are large missing sections, holes or
                    collapsed pipes, the pipe will need to be replaced.
                </p>
                <p>
                    The traditional method of sewer line replacement is through excavation of the sewer pipe and replacing
                    it with a new ABS sewer pipe. For small breaks, replacement is often the most cost effective for
                    sections that are underneath the home or easily accessible earth.

                </p>
                <p>
                    If the broken line is under concrete, the entire line is damaged, or if you want to save expensive
                    landscaping, then pipe bursting is a great alternative.
                </p>
                <p>
                    Pipe bursting simultaneously breaks up the old, damaged line while replacing it with a new High Density
                    Polyethylene pipe (HDPE). Pipe bursting has many benefits, and does not require full trenching, removing
                    the damaged line and can be completed in half the time of traditional replacement.
                </p>
                <p>
                    Our team specializes in sewer line repair and replacement options to suit your unique needs and budget.
                    We have more than 20 years of experience using advanced technology to solve damaged sewer main pipes and
                    we are committed to exceptional customer service.
                </p>
                <div className='kitchenBottomContainer'>
                    <img src={sewer5} alt="#" className='kitchenBottom'/>
                </div>
            </ServiceChild>
        </Layout>
    );
};

export default SewerLineService;