import React from 'react';
import Layout from "../../../components/layout";
import TopContent from "../../../components/topContent";
import Quote from "../../../components/quote";
import ImageGallery from 'react-image-gallery';
import LeftNav from "../../../assets/svges/leftNav";
import RightNav from "../../../assets/svges/rightNav";
import VideoComponent from "../../../components/videoComponent";
import Roofing from "../../../assets/svges/roofing";
import Plumbing from "../../../assets/svges/plumbing";
import Bathroom from "../../../assets/svges/bathroom";
import Kitchen from "../../../assets/svges/kitchen";
import './style.scss'

const GalleryCategories = () => {
    const leftNav = (onClick) => {
        return <div className='leftNav' onClick={onClick}>
            <LeftNav/>
        </div>
    }
    const rightNav = (onClick) => {
        return <div className='rightNav' onClick={onClick}>
            <RightNav/>
        </div>
    }
    const logos = {
        Roofing: <Roofing fill='#DBB257' width={51} height={47}/>,
        Plumbing: <Plumbing fill='#DBB257' width={51} height={47}/>,
        Bathroom: <Bathroom fill='#DBB257' width={51} height={47}/>,
        Kitchen: <Kitchen fill='#DBB257' width={51} height={47}/>,
    }
    const images = [
        {
            original: 'https://picsum.photos/id/1018/1000/600/',
            thumbnail: 'https://picsum.photos/id/1018/250/150/',
        },
        {
            original: 'https://picsum.photos/id/1015/1000/600/',
            thumbnail: 'https://picsum.photos/id/1015/250/150/',
        },
        {
            original: 'https://picsum.photos/id/1019/1000/600/',
            thumbnail: 'https://picsum.photos/id/1019/250/150/',
        },
        {
            original: 'https://picsum.photos/id/1019/1000/600/',
            thumbnail: 'https://picsum.photos/id/1019/250/150/',
        },
        {
            original: 'https://picsum.photos/id/1019/1000/600/',
            thumbnail: 'https://picsum.photos/id/1019/250/150/',
        },
        {
            original: 'https://picsum.photos/id/1019/1000/600/',
            thumbnail: 'https://picsum.photos/id/1019/250/150/',
        },
    ];
    return (
        <Layout>
            {/*<PageTitle title={`Gallery - ${a}`}/>*/}
            <div style={{background: '#f1f1f1', display: 'flex', flexDirection: 'column'}}>
                <TopContent
                    contentClassName='galleryTitle'
                    // image={createImageUrl('cover_image', imgFolder, gallery?.cover_image)}
                    // title={gallery.title}
                />
                <div className='galleryCategoriesMid'>
                    <div className="galleryCategoriesMidContent">
                        <div className='galleryCategoriesMidContentTop'>
                            <div className='galleryIcon'>
                                {logos.Bathroom}
                            </div>
                            <div>
                                <h2>
                                    Shingle roof
                                </h2>
                                <p>
                                    COMPOSITION, ASPHALT SHINGLE, COOL ROOF
                                </p>
                            </div>
                        </div>
                        <ImageGallery
                            items={images}
                            showPlayButton={false}
                            showFullscreenButton={false}
                            showBullets={false}
                            renderLeftNav={(onClick) => leftNav(onClick)}
                            renderRightNav={(onClick) => rightNav(onClick)}
                        />
                    </div>
                    <p className='slideDes'>
                        Check out some of our best roofing projects we have completed for our clients.
                        From completing complex roof repairs to installing brand new roofs.
                    </p>
                    <VideoComponent/>
                </div>
                <Quote/>
            </div>
        </Layout>
    );
};

export default GalleryCategories;