import React from "react";
import {Button, Form, Input} from "antd";
import {FormikProvider} from "formik";
import InputFiled from "../../../components/forms/inputField";
import Resource from "../../../assets/svges/resource";
import ShapeDone from "../../../assets/svges/shapeDone";
import Phone from "../../../assets/svges/phone";
import Email from "../../../assets/svges/email";
import UserIcon from "../../../assets/svges/user";
import MarkerIcon from "../../../assets/svges/mrkerIcon";
import 'antd/dist/antd.min.css';
import "./style.scss";
import useFormikHook from "../../../hooks/useFormikHook";

const MessageContent = () => {
    const {formik} = useFormikHook();
    return (
        <>
            <div className='message-content'>
                <div className='content'>
                    <div className='title-content'>
                        <h5 className='title'>CONTACT</h5>
                    </div>
                </div>
            </div>
            <div className='content-items'>
                <div className='form-content'>
                    <h2 className='title gradient-text'>SEND US A MESSAGE</h2>
                    <Form onFinish={formik.handleSubmit}>
                        <FormikProvider value={formik}>
                            <InputFiled
                                className="input"
                                name="name"
                                placeholder="Full Name"
                                prefix={<UserIcon width={20} height={20} fill={'#8F8F8F'}/>}
                                bordered={false}
                            />
                            <InputFiled
                                className="input required-input"
                                name="phone"
                                placeholder='Phone'
                                formItemClassName="formItem"
                                prefix={<Phone width={20} height={20} fill={'#8F8F8F'}/>}
                                bordered={false}
                            />
                            <InputFiled
                                className="input required-input"
                                name="email"
                                placeholder='Email'
                                formItemClassName="formItem"
                                prefix={<Email width={20} height={20} fill={'#8F8F8F'}/>}
                                bordered={false}
                            />
                            <InputFiled
                                className="input textarea"
                                name="message"
                                placeholder='Message'
                                formItemClassName="formItem"
                                asComponent={Input.TextArea}
                                bordered={false}
                                rows={3}
                                autosize={{minRows: 2, maxRows: 3}}
                            />
                            <Button className='submit-button' htmlType="submit">Submit</Button>
                        </FormikProvider>
                        <p className='text'>
                            Company Name not sell, share, or trade customer information. Your privacy is very important
                            to us.
                        </p>
                    </Form>
                </div>
                <div className="texts">
                    <div className='content'>
                        <h1 className='title gradient-text'>Talk to us!</h1>
                        <p className='text'>
                            Our customer service is happy to help. Please fill out the form below and will get back to
                            you
                            promptly regarding your request.
                        </p>
                    </div>
                </div>
                <div className='information-content'>
                    <div className="black-block">
                        <div className='top'><Resource width={44} height={54}/></div>
                        <div className='content'>
                            <div className='item'>
                                <span><ShapeDone width={20} height={20}/></span>
                                <p className='text'>Monday - Friday 8:00 AM – 6:00 PM</p>
                            </div>
                            <div className='item'>
                                <span><ShapeDone width={20} height={20}/></span>
                                <p className='text'>Saturday - Sunday 9:00 AM – 2:00 PM</p>
                            </div>
                            <div className='item'>
                                <span><Phone width={20} height={20}/></span>
                                <p className='text'>(424) 384 4444</p>
                            </div>
                            <div className='item'>
                                <span><Email width={20} height={20}/></span>
                                <p className='text'>info@timelessconstructiongroup.com</p>
                            </div>
                            <div className='item'>
                                <span><MarkerIcon width={20} height={20}/></span>
                                <p className='text'>13739 Ventura Blvd, Sherman Oaks, CA 91423</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default MessageContent;