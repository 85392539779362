import {useSelector} from "react-redux";
import {useCallback, useEffect, useMemo, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {isEmpty} from "lodash";

function useContainer() {
    const {pathname} = useLocation();
    const [activeUnderLine, setActiveUnderLine] = useState('');
    const [dropDownVisible, setDropDownVisible] = useState({});
    const {defaultData} = useSelector(({general}) => general);

    const getChildren = useCallback((parentName) => {
        return defaultData?.[parentName]?.reduce((acc, item) => {
            item?.children?.forEach(value => {
                acc.push({
                    name: value?.title,
                    path: `/${parentName}/${value?.id}`,
                    children: value?.categories?.data?.map(category => ({
                        path: `/${parentName}/${value?.id}/category`,
                        name: category?.title,
                        type: category?.slug,
                    })),
                })
            })
            return acc;
        }, [])
    }, [defaultData]);

    const menu = useMemo(() => ([
        {
            name: 'home',
            path: '/home',
            children: [],
        },
        {
            name: 'service',
            path: '/services',
            children: getChildren('services') || [],
        },
        {
            name: 'gallery',
            path: '/gallery',
            children: getChildren('gallery') || [],
        },
        {
            name: 'team',
            path: '/team',
            children: [],
        },
        {
            name: 'about us',
            path: '/aboutUs',
            children: [],
        },
        {
            name: 'contact',
            path: '/contact',
            children: [],
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ]), [defaultData]);

    const dropDownMenu = (values) => {
        return values.map(item => {
            item = {
                ...item,
                key: item.path + item.name,
                label: <Link className='dropdown-menu-name' to={{
                    pathname: item.path,
                    search: `${item.type ? `?type=${item.type}` : ''}`
                }}>
                    {item.name}
                </Link>,
                children: !isEmpty(item.children) ? dropDownMenu(item.children) : null,
            }
            return item
        });
    };

    const handleDropDownOpen = (e, name) => {
        setDropDownVisible(prev => ({...prev, [name]: e}));
    }

    const pathnameUpdateHandler = () => {
        setActiveUnderLine(`/${pathname.split('/')[1]}`);
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant",
        });
    }

    useEffect(pathnameUpdateHandler, [pathname]);

    return {
        menu,
        activeUnderLine,
        dropDownVisible,
        handleDropDownOpen,
        dropDownMenu,
    }
}

export default useContainer;
