import React from 'react';
import Layout from "../../components/layout";
import AboutCard from "./aboutCard";
import FreeInspection from "../../assets/svges/freeInspection";
import Convenience from "../../assets/svges/convenience";
import QualityProject from "../../assets/svges/qualityProject";
import Professional from "../../assets/svges/professional";
import Budget from "../../assets/svges/budget";
import FiveStarts from "../../assets/svges/fiveStars";
import Quote from "../../components/quote";
import PageTitle from "../../components/helmet";
import './style.scss';
import TopContent from "../../components/topContent";
import aboutUsBackground from "../../assets/images/aboutUsBackground.png";

const AboutUs = () => {
    const cardData = [
        {
            icon: <FreeInspection width={83} height={83} />,
            title: 'Free Inspection and Estimates',
            text: 'We will inspect the job, do the measurements and provide you with detailed free estimate and options of project plans.'
        },
        {
            icon: <Convenience width={83} height={83}  />,
            title: 'Convenience and simple process',
            text: 'We will make sure to provide you with the most stress-free experience from inspection to completion of the projects within the set time frames.'
        },
        {
            icon: <QualityProject width={83} height={83} />,
            title: 'Quality Projects',
            text: 'In our company we think quality of work is what makes our projects truly TIMELESS. We’ve been in business for such a long time due to the quality we provide, and many happy clients are proof of that.'
        },
        {
            icon: <Professional width={83} height={83}/>,
            title: 'Experienced and Professional Team',
            text: 'We are team of experts ready to serve all your construction needs and provide with exceptional results. From professional contractors to our management team, we will ensure that the job is done correctly and in timely manner.'
        },
        {
            icon: <Budget width={83} height={83}/>,
            title: 'Budget and Financing',
            text: 'We know one of the most important factors of remodeling process is budget. First, we try to give you the most reasonable price. Second, we offer variety of financing options.'
        },
        {
            icon: <FiveStarts width={83} height={83} />,
            title: '5-star Rated Contractor',
            text: 'During these years we were able to gain our customers’ trust and many good reviews due to hard work and ability of paying attention to details.'

        },
    ]
    return (

        <Layout>
            <PageTitle title='About Us - Timeless'/>
            <TopContent
                contentClassName='aboutUsTop'
                image={aboutUsBackground}
                title='ABOUT US'
                text="Timeless Construction is a family owned and operated company. It was founded in 1998 as a
                                humble construction
                                company and grew into a big, trustworthy construction group. During these years we built
                                very strong
                                relationship with our customers due to quality conscious approach and affordable
                                prices."
            />
            <div className="aboutUsContainer">
                <div className='aboutUsMidContent'>
                    <div className='aboutUsCenter'>
                        <h2>WHAT MAKES US EXCEPTIONAL</h2>
                        <div className='aboutUsCards'>
                            {
                                cardData.map((el, index) => <AboutCard {...el} key={index}/>)
                            }
                        </div>
                    </div>
                    <h2>WHAT WE OFFER</h2>
                    <p className='text'>We can help you with all your remodeling and construction needs. We provide
                        large range of
                        services,
                        including roofing, plumbing, electrical, solar system, HVAC, foundation and other services.</p>
                    <Quote/>
                </div>
            </div>
        </Layout>
    );
};

export default AboutUs;