import axios from 'axios';
import {BASE_URL} from "../constants/globals";

const httpClient = axios.create({
    baseURL: BASE_URL,
});

httpClient.interceptors.request.use((config) => {
    return config
});

httpClient.interceptors.response.use(
    (response) => response,
    (error) => {
        return Promise.reject(error)
    }
);

export default httpClient;