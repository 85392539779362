import React from 'react';
import Menu from "../../components/menu";
import AboutDes from "../../components/aboutDesContent";
import Layout from "../../components/layout";
import TopSlider from "./TopSlider";
import PageTitle from "../../components/helmet";
import BottomSlider from "./bottomSlider";
import CardContent from "./cardContnet";
import Introduction from "./introduction";

const Home = () => {
    return (
        <Layout>
            <PageTitle title='Timeless'/>
            <TopSlider/>
            <div className='mobileScreen'>
                <Menu/>
                <Introduction/>
            </div>
            <BottomSlider/>
            <div className='mobileScreen'>
                <CardContent/>
                <AboutDes/>
            </div>
        </Layout>
    );
}

export default Home;