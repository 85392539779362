import * as React from "react"
import SvgGradient from "../../components/svgGradient";
import { v4 as uniqueId } from 'uuid';

function FinancialOption(props) {
const id = uniqueId()
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 245.03 214.81"
            {...props}
            width={90}
            height={80}
            fill={'#AF7822'}
        >
            <SvgGradient id={id}/>
            <g data-name="\u0421\u043B\u043E\u0439 2" fill={props.fill? props.fill :`url(#${id})`}>
                <g data-name="\u0421\u043B\u043E\u0439 1">
                    <path d="M10.61 70.89c0-3.18-.12-6.17.08-9.15a3.55 3.55 0 011.73-2.27q27.1-14.86 54.29-29.6Q93.53 15.26 120.35.64a3.92 3.92 0 014.31 0q53.51 29.21 107.11 58.26c2 1.11 2.86 2.31 2.67 4.6s-.05 4.8-.05 7.38zm198.62-10.53l.19-.4c-.46-.28-.91-.6-1.39-.84q-42-21.7-83.95-43.31a4.28 4.28 0 00-3.38.09Q78.54 37.47 36.46 59.19a18.67 18.67 0 00-1.67 1.17zM138.6 92.42v69.09h4.52c3.65.09 5.95 2.14 6 5.29s-2.25 5.44-5.82 5.45q-20.72.08-41.44 0c-3.6 0-5.91-2.18-5.94-5.34s2.26-5.3 5.87-5.39c1.49-.05 3 0 4.61 0v-69.1h-4.6c-3.59-.11-5.87-2.24-5.87-5.42s2.28-5.31 5.93-5.32q20.72-.06 41.44 0c3.49 0 5.75 2.13 5.82 5.18.08 3.2-2.19 5.42-5.73 5.55-1.52.06-3.02.01-4.79.01zm-21.3.11v68.82h10.34V92.53zM202.58 161.5h4c4 0 6.41 2 6.39 5.35s-2.38 5.39-6.4 5.4h-40.39c-4 0-6.37-2.06-6.33-5.43s2.31-5.26 6.18-5.33h4.29V92.42h-4.71c-3.49-.11-5.72-2.16-5.76-5.24 0-3.24 2.17-5.47 5.75-5.49q20.85-.08 41.68 0c3.43 0 5.71 2.18 5.74 5.26s-2.13 5.27-5.57 5.46c-1.56.09-3.13 0-4.87 0zm-21.36-69v68.82h10.36v-68.8zM74.7 161.51h4.61c3.61.11 5.86 2.22 5.83 5.43s-2.21 5.28-5.69 5.3q-20.83.08-41.68 0c-3.59 0-5.85-2.31-5.8-5.48s2.36-5.2 6-5.25h4.42v-69.1c-1.57 0-3.12.06-4.67 0-3.59-.16-5.82-2.34-5.75-5.55.07-3.06 2.34-5.17 5.81-5.18h41.43c3.66 0 5.91 2.13 5.93 5.32s-2.29 5.36-6.1 5.42H74.7zm-21.33-69v68.84h10.34V92.51zM122.53 214.8H5.45c-3.23-.13-5.45-2.26-5.45-5.29a5.08 5.08 0 015.38-5.3c10.65-.07 21.3-.05 32-.05h201.41c3.45 0 5.48 1.39 6.08 4 .83 3.61-1.72 6.58-5.75 6.59h-34.45zM122.49 182.85H223.83a5.18 5.18 0 015.16 5.29 5 5 0 01-5.15 5.28c-6.07.1-12.14 0-18.22 0H22.9a12 12 0 01-3.45-.32 5.23 5.23 0 01-3.38-6c.39-2.55 2.56-4.3 5.57-4.31h100.9z" />
                </g>
            </g>
        </svg>
    )
}

export default FinancialOption
