import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {makeAction} from "../../state/makeAction";
import {FETCH_GALLERY_REQUEST} from "../../state/gallery/types";

function useContainer() {
    const dispatch = useDispatch();
    const { gallery, imgFolder } = useSelector(({gallery}) => gallery)
    const onMountHandler = () => {
        dispatch(makeAction(FETCH_GALLERY_REQUEST))
    }

    useEffect(onMountHandler, []);

    return {
        gallery,
        imgFolder,
    }

}

export default useContainer;