import { useEffect, useRef, useState } from 'react';

const useMapLocation = (location) => {
    const [map, setMap] = useState(null);
    const mapContainerRef = useRef(null);

    const createMap = () => {
        if (
            !mapContainerRef.current ||
            map ||
            (!Boolean(location?.latitude) && !Boolean(location?.longitude))
        ) {
            return;
        }

        const mapObj = new window.google.maps.Map(mapContainerRef.current, {
            zoom: 14,
            center: { lat: location.latitude, lng: location.longitude },
            draggable: true,
        });

        setMap(mapObj);

        // eslint-disable-next-line no-new
        new window.google.maps.Circle({
            map: mapObj,
            center: { lat: location.latitude, lng: location.longitude },
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(createMap);

    return { map, mapContainerRef };
};

export default useMapLocation;
