import * as React from "react"
import SvgGradient from "../../components/svgGradient";
import { v4 as uniqueId } from 'uuid';


function Bathroom(props) {
    const id = uniqueId()
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 294.14 274.66"
            {...props}
        >
            <SvgGradient id={id}/>
            <g data-name="\u0421\u043B\u043E\u0439 2"  fill={props.fill? props.fill :`url(#${id})`}>
                <g data-name="\u0421\u043B\u043E\u0439 1">
                    <path d="M279.54 147.13h-4.88v-103A43.93 43.93 0 00230.52 0c-21.73 0-37.3 16.09-39.08 39.66a24.2 24.2 0 00-19.77 24.08 4.61 4.61 0 004.88 4.88h39.26a4.61 4.61 0 004.88-4.88 24.29 24.29 0 00-19.42-24c1.43-15.57 10.8-29.94 29.19-29.94a34.11 34.11 0 0134.31 34.31v103H117.24a24.23 24.23 0 00-24-19.6c-4.89-5.86-11.78-9.83-19.6-9.83a24.25 24.25 0 00-21.55 13.22 29.24 29.24 0 00-12.76-3.45 24.2 24.2 0 00-24 19.6h-.46A14.65 14.65 0 000 161.84a14.45 14.45 0 0014.71 14.71h5.92L33.85 229a34.53 34.53 0 0033.33 26h3.91l-6.38 12.76a4.31 4.31 0 002 6.38 2.85 2.85 0 002 .52 5.15 5.15 0 004.43-2.47L81.61 255H217.7l8.3 16.67a4.73 4.73 0 004.42 2.93c1 0 1.5 0 2-1 2-1 2.93-3.91 2-6.38l-6.15-12.3a34.69 34.69 0 0032.13-25.46l13.33-53h5.75a14.46 14.46 0 0014.71-14.72 14.28 14.28 0 00-14.65-14.61zM210.4 58.85h-27.93a15 15 0 0114.2-9.85 14.3 14.3 0 0113.73 9.85zM39.25 137.3c4.43 0 8.34 1.95 11.78 5.4 1 1.5 2.94 2 4.89 1.5s2.93-2 3.45-3.91c1.49-7.36 7.35-12.76 14.71-12.76 5.4 0 10.29 2.47 12.76 7.36a5 5 0 005.4 2.47h1.5a14.25 14.25 0 0113.73 9.82H25.52a14.22 14.22 0 0113.73-9.88zm212.36 89.25a25.12 25.12 0 01-24 18.62H67.7a25.19 25.19 0 01-24-18.62l-12.76-50h232.45zm27.93-59.82H14.71a4.89 4.89 0 010-9.78h264.77a4.89 4.89 0 01.06 9.78z" />
                    <path d="M176.55 90.69a4.62 4.62 0 004.89-4.88v-2.48a4.89 4.89 0 00-9.77 0v2.48a4.61 4.61 0 004.88 4.88zM176.55 113.28a4.92 4.92 0 004.89-4.89V103a4.89 4.89 0 10-9.77 0v5.4a4.62 4.62 0 004.88 4.88zM176.55 132.41a4.62 4.62 0 004.89-4.88v-2.47a4.89 4.89 0 10-9.77 0v2.47a4.61 4.61 0 004.88 4.88zM196.15 90.69a4.62 4.62 0 004.85-4.88v-2.48a4.89 4.89 0 00-9.77 0v2.48a4.61 4.61 0 004.92 4.88zM191.27 108.39a4.62 4.62 0 004.88 4.89 4.92 4.92 0 004.89-4.89V103a4.89 4.89 0 10-9.77 0zM191.27 127.47a4.89 4.89 0 109.77 0V125a4.89 4.89 0 00-9.77 0zM215.81 90.69a4.61 4.61 0 004.88-4.88v-2.48a4.89 4.89 0 00-9.77 0v2.48a4.55 4.55 0 004.89 4.88zM210.86 108.39a4.62 4.62 0 004.89 4.89 4.91 4.91 0 004.88-4.89V103a4.89 4.89 0 10-9.77 0v5.4zM210.86 127.47a4.89 4.89 0 109.77 0V125a4.89 4.89 0 00-9.77 0z" />
                </g>
            </g>
        </svg>
    )
}

export default Bathroom
