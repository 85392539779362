import React from 'react';
import './style.scss'

const AboutCard = ({title, text, icon}) => {
    return (
        <div className='aboutCardContent'>
            <div>{icon}</div>
            <h3>{title}</h3>
            <p>{text}</p>
        </div>
    );
};

export default AboutCard;