import * as types from "./types";

const initialState = {
    services: {},
    imgFolder: '',
}

const serviceReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_SERVICES_SUCCESS:
            return { ...state, services: action.payload.data[0], imgFolder: action.payload.imgFolder }
        default:
            return state;
    }
};

export default serviceReducer;