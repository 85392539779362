import * as React from "react";

function Star(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 122.21 110.98"
            {...props}
        >
            <g data-name="\u0421\u043B\u043E\u0439 2">
                <path
                    d="M19 101.41c.3-9.45.57-18.91.92-28.36a4.3 4.3 0 00-1.13-3.3Q11.11 61 3.53 52.23a15.15 15.15 0 01-3.28-6.52 8 8 0 014.68-9.52 60.1 60.1 0 018.34-2.81c7.78-2.21 15.6-4.31 23.39-6.5a2.47 2.47 0 001.15-1c4.69-6.86 9.34-13.75 14-20.59a11.61 11.61 0 016-4.73 9.6 9.6 0 0110.4 2.51 75.7 75.7 0 016.63 8.35c4 5.44 7.89 11 11.87 16.41a3 3 0 001.57 1c8 2 16.13 3.94 24.17 6a19.85 19.85 0 015.47 2.37c4.19 2.61 5.46 6.92 3.13 11.27a48.72 48.72 0 01-5.12 7.16c-4.86 6-9.81 12-14.7 18a2.15 2.15 0 00-.38 1.34c.46 7.72 1 15.45 1.45 23.17a15.48 15.48 0 01-1.32 7.42c-1.6 3.57-5.16 5.4-9.65 5.08-3.69-.27-7-1.8-10.48-2.94-7-2.3-13.94-4.71-20.92-7a2.44 2.44 0 00-1.51 0c-7.79 2.85-15.56 5.77-23.36 8.6a20.86 20.86 0 01-8.37 1.7 7.49 7.49 0 01-7.51-6.74c-.15-.92-.2-1.86-.3-2.8z"
                    data-name="\u0421\u043B\u043E\u0439 1"
                />
            </g>
        </svg>
    )
}

export default Star;