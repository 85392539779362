import GoogleMapsOverlayView from "../googleMapsOverlayView";
import {Popover} from "antd";
import MarkerIcon from "../../assets/svges/mrkerIcon";
import "./style.scss";

const MapMarker = ({location, map, text}) => {
    if (!map || !Boolean(location) || !location.latitude || !location.longitude) {
        return null;
    }

    const PopoverContent = () => (
        <div className='popover-content'>
            <p className='location-address'>
                13739 Ventura Blvd, Sherman Oaks, CA 91423
            </p>
        </div>
    );

    return (
        <>
            <GoogleMapsOverlayView
                position={{lat: location?.latitude, lng: location?.longitude}}
                map={map}
            >
                <div className="map_pin">
                    <Popover content={PopoverContent} >
                        <MarkerIcon width={43} height={54} />
                    </Popover>
                </div>
            </GoogleMapsOverlayView>
        </>
    );
};

export default MapMarker;
