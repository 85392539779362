import yup from './../index'

const validationSchema = yup.object().shape({
    name: yup.string().required('Name is required field'),
    phone: yup.number().typeError('Must enter a phone number').required('Phone is required field'),
    email: yup.string().email().required('Email is required field'),
    message: yup.string().required('Message is required field'),
});

export default validationSchema;
