import * as React from "react"

function SeeMore(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={38.123}
            height={20.998}
            viewBox="0 0 38.123 20.998"
            {...props}
        >
            <defs>
                <linearGradient
                    id="a"
                    x1={-0.11}
                    y1={0.5}
                    x2={1.11}
                    y2={0.5}
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset={0} stopColor="#af7822" />
                    <stop offset={1} stopColor="#f1cc6c" />
                </linearGradient>
            </defs>
            <path
                data-name="\u041F\u0440\u044F\u043C\u043E\u0443\u0433\u043E\u043B\u044C\u043D\u0438\u043A 6 \u043A\u043E\u043F\u0438\u044F 2"
                d="M959.609 3212.919a1.006 1.006 0 01-1.091-.215l-17.678-17.6a.994.994 0 010-1.408l1.415-1.408a1 1 0 011.414 0l15.94 15.87 15.94-15.87a1 1 0 011.414 0l1.415 1.408a.992.992 0 010 1.408L960.7 3212.7a1 1 0 01-.707.291 1.012 1.012 0 01-.384-.072z"
                transform="translate(-940.548 -3191.997)"
                fill="url(#a)"
            />
        </svg>
    )
}

export default SeeMore
