import {createLogic} from 'redux-logic';
import {FETCH_DEFAULT_REQUEST, FETCH_DEFAULT_SUCCESS} from "../types";
import {makeAction} from "../../makeAction";

const fetchDefaultData = createLogic({
    type: FETCH_DEFAULT_REQUEST,
    latest: true,

    async process({httpClient, action}, dispatch, done) {
        try {
            const {data} = await httpClient.get(`/default`);
            dispatch(makeAction(FETCH_DEFAULT_SUCCESS, data))
        } catch (e) {
            console.log(e);
        }
        done();
    },
});

export default fetchDefaultData;